import { AfterContentChecked, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { MinimalVideo } from 'src/app/main/interfaces/minimal.video'
import { animations } from '../animations'
import { Video } from 'src/app/main/interfaces/video.interface'

@Component({
  selector: 'ui-video-preview',
  templateUrl: './video-preview.component.html',
  styleUrls: ['./video-preview.component.scss'],
  animations: [animations.fadeInOut],
})
export class VideoPreviewComponent implements AfterContentChecked {
  @Input()
  public video?: MinimalVideo

  @Input()
  public takeInputs = true

  @ViewChild('videoElement')
  protected videoElement?: ElementRef<HTMLVideoElement>

  @Output()
  public loadedMetadata = new EventEmitter<VideoPreviewComponent>()

  @Input()
  public showPointerIfNoPreview = true

  @Input()
  public showDuration = true

  protected videoDuration?: number
  protected videoState: 'loading' | 'loaded' | 'playing' | 'ended' = 'loading'
  protected videoStateForced = false

  ngAfterContentChecked(): void {
    if (!this.videoElement || !this.video) return

    const height = this.videoElement.nativeElement.clientHeight
    const aspectRatio = this.video.metadata.width / this.video.metadata.height

    this.videoWidth = height * aspectRatio
  }

  protected onLoadedMetadata() {
    if (!this.videoElement || !this.video) return

    this.videoState = 'loaded'
    this.videoDuration = this.videoElement.nativeElement.duration

    this.loadedMetadata.emit(this)
  }

  public resetVideo() {
    if (!this.videoElement) return
    this.videoElement.nativeElement.currentTime = 0
  }

  public playVideo(force = false, keepState = false) {
    if (!this.videoElement || (!this.takeInputs && !force)) return

    if ((this.videoState === 'playing' || this.videoState === 'ended') && !force) return

    this.videoState = 'playing'
    if (!keepState) {
      this.videoElement.nativeElement.currentTime = 0
    }
    this.videoElement.nativeElement.play()
    this.videoStateForced = force
  }

  public attemptStopVideo(force = false) {
    if (!this.videoElement || !this.takeInputs) return

    if (this.videoState === 'ended' && !force) return

    if (this.videoStateForced && !force) return

    this.videoState = 'loaded'
    this.videoElement.nativeElement.pause()
    this.videoStateForced = false
  }

  protected videoWidth = 0

  // protected get videoWidth() {
  //   if (!this.videoElement || !this.video) return 0

  //   // we'll go off the height of the video element, determine aspect ratio
  //   // and return desired width

  //   const height = this.videoElement.nativeElement.clientHeight
  //   const aspectRatio = this.video.metadata.width / this.video.metadata.height

  //   return height * aspectRatio
  // }
}
