import { CommonModule } from '@angular/common'
import { Component, computed, Input, input, OnChanges, OnInit, Signal, signal, SimpleChanges } from '@angular/core'
import { ApexChart, ApexStroke, NgApexchartsModule } from 'ng-apexcharts'
import { catchError, tap } from 'rxjs'
import { BasicProcessingLimitResponse } from 'src/app/main/services/organisation/organisation-api.service'
import { OrganisationService } from 'src/app/main/services/organisation/organisation.service'

type ChartOptions = {
  series: ApexAxisChartSeries
  chart: ApexChart
  xaxis: ApexXAxis
  yaxis: ApexYAxis
  title: ApexTitleSubtitle
  dataLabels: ApexDataLabels
  stroke: ApexStroke
  plotOptions: ApexPlotOptions
  tooltip: ApexTooltip
  states: ApexStates
}

@Component({
  selector: 'app-processing-minutes-usage-chart',
  standalone: true,
  imports: [CommonModule, NgApexchartsModule],
  templateUrl: './processing-minutes-usage-chart.component.html',
  styleUrl: './processing-minutes-usage-chart.component.scss',
})
export class ProcessingMinutesUsageChartComponent implements OnInit, OnChanges {
  constructor(private organisationService: OrganisationService) {}

  public data = input<BasicProcessingLimitResponse>()
  public month = input<number>(new Date().getMonth() + 1)
  public year = input<number>(new Date().getFullYear())
  public height = input<number>(350)

  protected processingMinutesData = signal<BasicProcessingLimitResponse | null>(null)

  ngOnInit(): void {
    const data = this.data()
    if (!data) {
      this.setData()
      return
    }

    this.processingMinutesData.set(data)
  }

  private cachedOrganisationUuid?: string

  private setData() {
    // this.processingMinutesData.set
    this.organisationService.getActiveOrganisation().subscribe(organisation => {
      // this.isError = false
      if (organisation?.uuid === this.cachedOrganisationUuid) return

      // this.isLoading = true
      // this.processingMinuteData.set(null)
      this.processingMinutesData.set(null)

      this.cachedOrganisationUuid = organisation?.uuid

      if (!organisation) return

      this.organisationService
        .getProcessingLimits(organisation.uuid, {
          month: this.month(),
          year: this.year(),
        })
        .pipe
        // catchError(() => {
        //   this.isError = true
        //   this.isLoading = false
        //   return of(null)
        // }),
        // tap(() => (this.isLoading = false))
        ()
        .subscribe(processingLimits => {
          if (!processingLimits) return

          this.processingMinutesData.set(processingLimits)

          // this.processingMinuteData = this.getVariableChartData(
          //   this.secondsToMinutes(processingLimits.usedProcessingSeconds),
          //   this.secondsToMinutes(processingLimits.maxProcessingSeconds)
          // )

          // this.usedSeconds = processingLimits.usedProcessingSeconds
          // this.maxSeconds = processingLimits.maxProcessingSeconds
        })
    })
  }

  private chartSeriesData = computed(() => {
    const data = this.processingMinutesData()

    if (!data) {
      return []
    }

    const normalizedProcessingMinutes = Math.round((data.usedProcessingSeconds / data.maxProcessingSeconds) * 100)

    return [normalizedProcessingMinutes]
  })

  protected chartOptions: Signal<Partial<ChartOptions>> = computed(() => {
    return {
      series: [
        {
          data: this.chartSeriesData(),
          color: '#A2D1FD',
        },
      ],
      chart: {
        height: 32,
        type: 'bar',
        stacked: true,
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 500,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '50%',
          barHeight: 32,
          borderRadius: 4,
          borderRadiusApplication: 'around',
          colors: {
            backgroundBarColors: ['#f3f3f3'],
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
      xaxis: {
        max: 100,
      },
    }
  })

  ngOnChanges(changes: SimpleChanges): void {
    const dataChange = changes['data']
    if (dataChange) {
      this.processingMinutesData.set(dataChange.currentValue)
    }
  }
}
