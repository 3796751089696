<div class="-mt-4">
  <div class="transition-all overflow-y-auto">
    <div class="flex mt-4">
      <div class="grow">
        <h1 class="text-2xl font-bold text-dark flex gap-1 items-center">{{ getTitle() }}</h1>
        <p class="font-light text-sm text-neutral-500">{{ getSubtitle() }}</p>
      </div>
      <div class="w-24 shrink-0 flex items-end justify-center flex-col gap-1 -mt-2">
        <ui-component-layout-preview [component]="component" [template]="template" class="h-20" />
      </div>
    </div>
    @if (currentStep === 0) { @if (manualEditing) {
    <app-action-set-video-source
      [component]="component"
      [template]="template"
      [videoType]="videoType()"
      [canChangeVideoType]="canChangeVideoType()"
      (videoTypeChanged)="videoType.set($event)"
      (validityChanged)="validityState = $event"
      (formUpdated)="patchFirstFormValue($event)" />
    } @else {
    <app-action-set-video-library (clipSelected)="onVideoLibraryClipSelected($event)" />
    } } @if (currentStep === 1 && manualEditing) {
    <app-action-set-video-data
      [template]="template"
      [component]="component"
      [subclipMode]="form.controls.subclipMode.value"
      (formChange)="patchSecondFormValue($event)" />
    }
  </div>
  <button
    class="w-full button-primary mt-8 flex items-center justify-center gap-2"
    [disabled]="!isValid()"
    (click)="nextStep()">
    Next step
  </button>
</div>
