import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { AccountTokenResponse } from '../../interfaces/account-token-response'
import { environment } from 'src/app/environments/environment'
import { AccountInformation } from '../../interfaces/account-information'

export type UpdateAccountInformationData = Exclude<Partial<AccountInformation>, 'id' | 'email'>

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private http: HttpClient) {}

  public loginViaEmail(email: string, password: string): Observable<AccountTokenResponse> {
    return this.http.post<AccountTokenResponse>(`${environment.baseApiUrl}/auth/login/`, {
      email,
      password,
    })
  }

  public createAccount(accountInformation: { firstName: string; lastName: string; email: string; password: string }) {
    return this.http.post<AccountTokenResponse>(`${environment.baseApiUrl}/users/`, accountInformation)
  }

  public getAccountInformation() {
    return this.http.get<AccountInformation>(`${environment.baseApiUrl}/users/me/`)
  }

  public updateAccountInformation(data: UpdateAccountInformationData) {
    return this.http.patch<AccountInformation>(`${environment.baseApiUrl}/users/me/`, data)
  }
}
