import { Component, EventEmitter, input, Output } from '@angular/core'
import { IconModule } from '../icon/icon.module'
import { BytesToStringPipe } from './bytes-to-string.pipe'

@Component({
  selector: 'ui-file-upload',
  standalone: true,
  imports: [IconModule, BytesToStringPipe],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
})
export class FileUploadComponent {
  @Output()
  public fileUploaded = new EventEmitter<File>()

  public maxFileSize = input(1024 * 1024 * 10) // 10MB

  protected onFileChange(event: Event) {
    const input = event.target as HTMLInputElement
    if (input.files) {
      const file = input.files[0]
      this.fileUploaded.emit(file)
    }
  }
}
