import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/app/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  constructor(private http: HttpClient) {}

  public loadStripeCheckout() {
    return this.http.post<{ checkoutUrl: string }>(`${environment.baseApiUrl}/payment/create-checkout-session/`, {})
  }
}
