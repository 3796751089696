import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/app/environments/environment'
import { CreateProjectInput, Project } from '../../interfaces/project'

export interface ThirdPartyVideoInfo {
  title: string
  thumbnail: string
  duration: number
}

export interface VideoSubtitleType {
  name: string
  previewVideo: string
  identifier: string
}

@Injectable({
  providedIn: 'root',
})
export class VideoCreationApiService {
  constructor(private http: HttpClient) {}

  public getYouTubeVideoInfo(videoId: string) {
    return this.http.get<ThirdPartyVideoInfo>(`${environment.baseApiUrl}/video-data/youtube/${videoId}/`)
  }

  public getTwitchVideoInfo(videoId: string) {
    return this.http.get<ThirdPartyVideoInfo>(`${environment.baseApiUrl}/video-data/twitch/${videoId}/`)
  }

  // public createAiProject(data: {
  //   videoUrl?: string
  //   uploadedVideoUuid?: string
  //   themes: string[]
  //   selectedCaptions: string
  //   organisationUuid: string
  // }) {
  //   return this.http.post<unknown>(`${environment.baseApiUrl}/projects/`, data)
  // }

  public createProject(data: CreateProjectInput) {
    const { image, name, organisation, projectType, videoUrl, uploadedVideo } = data

    const formData = new FormData()
    formData.append('name', name)
    formData.append('project_type', projectType)

    // uploadedvideo takes priority over videoUrl
    if (uploadedVideo) {
      formData.append('uploaded_video', uploadedVideo)
    } else if (videoUrl) {
      formData.append('video_url', videoUrl)
    }

    if (image) {
      formData.append('image', image)
    }

    return this.http.post<Project>(`${environment.baseApiUrl}/projects/`, formData, {
      params: { organisation },
    })
  }

  public generateClipsForProject({
    projectUuid,
    organisationUuid,
    themes,
    selectedCaptions,
  }: {
    projectUuid: string
    organisationUuid: string
    themes: string[]
    selectedCaptions: string
  }) {
    return this.http.post(
      `${environment.baseApiUrl}/projects/${projectUuid}/generate-clips/`,
      {
        themes,
        selected_captions: selectedCaptions,
      },
      {
        params: { organisation: organisationUuid },
      }
    )
  }

  public getVideoSubtitleTypes() {
    return this.http.get<VideoSubtitleType[]>(`${environment.baseApiUrl}/subtitles/`)
  }
}
