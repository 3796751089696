import {
  ComponentRef,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core'
import { ToastService } from 'src/app/ui/toast/toast.service'
import { VideoComponentV0_0_0 } from '../interfaces/template/v0_0_0'
import { YouTubeDetailComponent } from '../components/detail/media/youtube/youtube.component'
import { WatermarkDetailComponent } from '../components/detail/util/watermark/watermark.component'
import { BehaviorSubject } from 'rxjs'

export type VideoDetailComponent = YouTubeDetailComponent | WatermarkDetailComponent

@Directive({
  selector: '[appCustomComponentDetailFactory]',
})
export class CustomComponentDetailFactoryDirective implements OnInit, OnChanges {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private toast: ToastService
  ) {}

  @Input()
  public baseComponent?: VideoComponentV0_0_0

  @Output()
  public component?: VideoDetailComponent

  @Output()
  public componentUpdated = new EventEmitter<{ component: VideoComponentV0_0_0; valid: boolean }>()

  ngOnInit(): void {
    this.setInputs()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['baseComponent']) {
      this.setInputs()
    }
  }

  private setInputs() {
    if (!this.baseComponent) return

    let comp: ComponentRef<VideoDetailComponent> | undefined

    this.viewContainerRef.clear()

    switch (this.baseComponent.classname) {
      case 'video.components.media.YouTubeComponent':
        this.handleYouTubeComponent()
        break

      case 'video.components.util.WatermarkComponent':
        comp = this.viewContainerRef.createComponent(WatermarkDetailComponent)
        comp.instance.component = this.baseComponent
        this.component = comp.instance
        break

      default:
        this.toast.error('Component class not found')
        console.error('Component class not found', this.baseComponent)
        throw new Error('Component class not found')
    }
  }

  private handleYouTubeComponent() {
    const comp = this.viewContainerRef.createComponent(YouTubeDetailComponent)
    comp.instance.component = this.baseComponent
    this.component = comp.instance
    comp.instance.form.valueChanges.subscribe(() => {
      const mutated = comp.instance.mutateComponent()
      // if (mutated) {
      if (this.baseComponent) {
        this.componentUpdated.emit({ component: mutated ?? this.baseComponent, valid: comp.instance.form.valid })
      }
      // }
    })
  }
}
