import { Injectable } from '@angular/core'
import { OrganisationApiService } from './organisation-api.service'
import { HttpClient } from '@angular/common/http'
import { BaseOrganisation, MinimalOrganisation, Organisation } from '../../interfaces/organisation.interface'
import { environment } from 'src/app/environments/environment'
import { BehaviorSubject, Observable, catchError, interval, map, of, switchMap, tap } from 'rxjs'
import { PaginatedResponse } from '../../interfaces/paginated-response'
import { ActivatedRoute, Router } from '@angular/router'
import { ToastService } from 'src/app/ui/toast/toast.service'

@Injectable({
  providedIn: 'root',
})
export class OrganisationService {
  constructor(
    private organisationApiService: OrganisationApiService,
    private route: ActivatedRoute,
    private toast: ToastService
  ) {
    this.setActiveOrganisationFromCachedOrganisation()

    this.refreshCachedOrganisations()

    this.setupValidityCheck()

    this.listenForOrganisationInvite()
  }

  private cacheKey = `${environment.appName.reverseDomainName}.${environment.activeOrganisationSuffix}`
  private activeOrganisation$ = new BehaviorSubject<Organisation | null>(null)
  private cachedUserOrganisations$ = new BehaviorSubject<MinimalOrganisation[] | null>(null)

  public getMaxMembers(organisation: Organisation | MinimalOrganisation | null) {
    // todo: when pricing is implemented, alter this number depending on org owner
    return 5
  }

  public getOrganisations() {
    return this.cachedUserOrganisations$
  }

  private setActiveOrganisationFromCachedOrganisation() {
    const activeOrganisation = localStorage.getItem(this.cacheKey)

    if (!activeOrganisation) return

    const organisation = JSON.parse(activeOrganisation) as Organisation
    this.activeOrganisation$.next(organisation)
  }

  private setCachedActiveOrganisation(organisation: Organisation | MinimalOrganisation) {
    if (!this.isOrganisation(organisation)) {
      this.organisationApiService.retrieveOrganisation(organisation.uuid).subscribe(organisation => {
        // * better to be safe than sorry attempting to do a cool recursive thing here
        localStorage.setItem(this.cacheKey, JSON.stringify(organisation))
      })
    }

    localStorage.setItem(this.cacheKey, JSON.stringify(organisation))
  }

  public getActiveOrganisation() {
    return this.activeOrganisation$.asObservable()
  }

  public setActiveOrganisation(organisation: MinimalOrganisation | Organisation) {
    const existingOrganisation = this.activeOrganisation$.getValue()
    this.activeOrganisation$.next(null)
    of(organisation)
      .pipe(
        switchMap(organisation => {
          if (!this.isOrganisation(organisation)) {
            return this.organisationApiService.retrieveOrganisation(organisation.uuid)
          }
          return of(organisation)
        }),
        catchError(() => {
          this.toast.error('Failed to set active organisation')
          this.activeOrganisation$.next(existingOrganisation)
          return []
        })
      )
      .subscribe(organisation => {
        this.activeOrganisation$.next(organisation)
        this.attemptAddOrganisationToCache(organisation)
        this.setCachedActiveOrganisation(organisation)
      })
  }

  private isMinimalOrganisation(organisation: MinimalOrganisation | Organisation): organisation is MinimalOrganisation {
    return !this.isOrganisation(organisation)
  }

  private isOrganisation(organisation: MinimalOrganisation | Organisation): organisation is Organisation {
    return (organisation as Organisation).owner !== undefined
  }

  private refreshCachedOrganisations() {
    this.organisationApiService
      .listOrganisations()
      .pipe(
        switchMap(organisations => {
          this.cachedUserOrganisations$.next(organisations.results)

          const activeOrg = this.activeOrganisation$.getValue()

          if (!activeOrg) return this.organisationApiService.retrieveOrganisation(organisations.results[0].uuid)

          return of(activeOrg)
        }),
        map(organisation => {
          if (!organisation) return
          const activeOrganisationUuid = this.activeOrganisation$.getValue()?.uuid

          return { updated: activeOrganisationUuid !== organisation.uuid, organisation }
        })
      )
      .subscribe(orgData => {
        if (orgData?.updated) {
          this.setCachedActiveOrganisation(orgData.organisation)
          this.activeOrganisation$.next(orgData.organisation)
        }
      })
  }

  private attemptAddOrganisationToCache(organisation: MinimalOrganisation | Organisation) {
    if (!organisation) return

    const existingOrganisations = this.cachedUserOrganisations$.getValue()

    if (!existingOrganisations) return

    const organisationExists = existingOrganisations.some(org => org.uuid === organisation.uuid)

    if (organisationExists) return

    existingOrganisations.push(organisation)

    this.cachedUserOrganisations$.next(existingOrganisations)
  }

  private checkedOrganisations: string[] = []
  private setupValidityCheck() {
    this.activeOrganisation$
      .pipe(
        switchMap(organisation => {
          if (!organisation || this.checkedOrganisations.includes(organisation.uuid)) return of(organisation)
          return this.organisationApiService.retrieveOrganisation(organisation.uuid)
        }),
        catchError(() => {
          const existingOrganisations = this.cachedUserOrganisations$.getValue()
          if (!existingOrganisations) return of(null)
          const updatedOrganisations = existingOrganisations.filter(
            org => org.uuid !== this.activeOrganisation$.getValue()?.uuid
          )
          this.cachedUserOrganisations$.next(updatedOrganisations)
          return of(updatedOrganisations[0])
        }),
        switchMap(organisation => {
          if (!organisation) return of(null)
          if (!this.isOrganisation(organisation)) {
            return this.organisationApiService.retrieveOrganisation(organisation.uuid)
          }
          return of(organisation)
        })
      )
      .subscribe(organisation => {
        if (!organisation) {
          return
        }
        this.checkedOrganisations.push(organisation.uuid)
        this.setCachedActiveOrganisation(organisation)
      })
  }

  private listenForOrganisationInvite() {
    this.route.queryParams.subscribe(params => {
      if (params['organisation_code']) {
        this.organisationApiService
          .joinOrganisation(params['organisation_code'])
          .pipe(
            catchError(() => {
              this.toast.error('Failed to join organisation')
              return of(null)
            })
          )
          .subscribe(organisation => {
            if (organisation) {
              this.setActiveOrganisation(organisation)
              this.toast.success(`Successfully joined ${organisation.name}!`)
              this.refreshCachedOrganisations()
            }
          })
      }
    })
  }

  public getOrganisationDetails(uuid: string) {
    return this.organisationApiService.retrieveOrganisation(uuid)
  }

  public createOrganisation(organisation: BaseOrganisation) {
    return this.organisationApiService.createOrganisation(organisation)
  }

  public makeOwner(organisationUuid: string, userUuid: string) {
    return this.organisationApiService.makeOwner(organisationUuid, userUuid)
  }

  public removeMember(organisationUuid: string, userUuid: string) {
    return this.organisationApiService.removeMember(organisationUuid, userUuid)
  }

  public deleteOrganisation(organisationUuid: string) {
    return this.organisationApiService.deleteOrganisation(organisationUuid).pipe(
      tap(() => {
        this.refreshCachedOrganisations() // could probably just remove it in-place ...
      })
    )
  }

  public partialUpdateOrganisation(organisationUuid: string, organisation: Partial<BaseOrganisation>) {
    return this.organisationApiService.partialUpdateOrganisation(organisationUuid, organisation)
  }

  public canInviteMembers(organisation: Organisation | null) {
    if (!organisation) return false

    if (organisation.isPrivate) return false

    return organisation.users.length < this.getMaxMembers(organisation)
  }

  public inviteMember(organisationUuid: string, email: string) {
    return this.organisationApiService.inviteMember(organisationUuid, email)
  }

  public listInvites(organisationUuid: string) {
    return this.organisationApiService.listInvites(organisationUuid)
  }

  public revokeInvite(organisationUuid: string, inviteId: number) {
    return this.organisationApiService.revokeInvite(organisationUuid, inviteId)
  }

  public getProcessingLimits(organisationUuid: string, data: { month: number; year: number }) {
    return this.organisationApiService.getProcessingLimits(organisationUuid, data)
  }

  public getProcessingLimitsStats(organisationUuid: string, data: { month: number; year: number }) {
    return this.organisationApiService.getProcessingLimitsStats(organisationUuid, data)
  }

  public getVideoCreationStats(organisationUuid: string, data: { month: number; year: number }) {
    return this.organisationApiService.getVideoCreationStats(organisationUuid, data)
  }

  public searchOrganisations(search: Partial<Organisation>) {
    return this.organisationApiService.listOrganisations(search)
  }
}
