import { CommonModule } from '@angular/common'
import { Component, computed, input, Input } from '@angular/core'
import { VideoComponentV0_0_0 } from 'src/app/main/interfaces/template/v0_0_0'
import { Video } from 'src/app/main/interfaces/video.interface'

@Component({
  selector: 'ui-component-layout-preview',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './component-layout-preview.component.html',
  styleUrl: './component-layout-preview.component.scss',
})
export class ComponentLayoutPreviewComponent {
  @Input({ required: true })
  public component!: VideoComponentV0_0_0

  @Input({ required: true })
  public template!: Video

  protected getCanvasSize(): { width: number; height: number } {
    return {
      width: this.template.data.metadata.width,
      height: this.template.data.metadata.height,
    }
  }

  protected getComponentBounds(): { x: number; y: number; width: number; height: number } {
    return {
      x: this.componentX,
      y: this.componentY,
      width: this.component.width,
      height: this.component.height,
    }
  }

  private get componentX() {
    const component = this.component

    if (typeof component.x === 'number') return component.x

    if (typeof component.x === 'string') {
      if (component.x === 'left') {
        return 0
      }

      if (component.x === 'right') {
        return this.template.data.metadata.width - component.width
      }

      if (component.x === 'center') {
        return (this.template.data.metadata.width - component.width) / 2
      }
    }

    return 0
  }

  private get componentY() {
    const component = this.component

    if (typeof component.y === 'number') return component.y

    if (typeof component.y === 'string') {
      if (component.y === 'top') {
        return 0
      }

      if (component.y === 'bottom') {
        return this.template.data.metadata.height - component.height
      }

      if (component.y === 'center') {
        return (this.template.data.metadata.height - component.height) / 2
      }
    }

    return 0
  }
}
