<div>
  <div class="mb-1 font-semibold text-darkest ml-8">Watermark</div>
  <div class="text-sm ml-8 text-darker font-light">Add a text watermark to your video.</div>
  <div class="mt-12">
    <form [formGroup]="form" class="flex flex-col items-end gap-2">
      <div class="text-right">
        <label class="text-xs text-mid font-light">Text Content</label>
        <input formControlName="text" type="text" class="input px-1 py-1 text-sm w-72 block text-right text-dark" />
      </div>
    </form>
  </div>
</div>
