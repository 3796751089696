import { Component, Input } from '@angular/core'
import { SidebarPanel } from '../sidebar.component'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-dashboard-panel',
  templateUrl: './dashboard-panel.component.html',
  styleUrls: ['./dashboard-panel.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class DashboardPanelComponent {
  @Input()
  public activePanel: SidebarPanel | null = null
}
