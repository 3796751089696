import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AuthModule } from '../auth/auth.module'
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component'
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component'
import { UiModule } from '../ui/ui.module'
import { AppRoutingModule } from '../app-routing.module'
import { RouterModule } from '@angular/router'
import { RecomendedTemplatesComponent } from './components/dashboard/recomended-templates/recomended-templates.component'
import { CreateVideoModalComponent } from './components/create-video-modal/create-video-modal.component'
import { YouTubeInputComponent } from './components/inputs/media/youtube/youtube.component'
import { CustomComponentDetailFactoryDirective } from './directives/custom-component-detail-factory.directive'
import { YouTubeDetailComponent } from './components/detail/media/youtube/youtube.component'
import { ReactiveFormsModule } from '@angular/forms'
import { WatermarkDetailComponent } from './components/detail/util/watermark/watermark.component'
import { TextInputComponent } from './components/inputs/core/text/text.component'
import { NumberInputComponent } from './components/inputs/core/number/number.component'
import { ColorInputComponent } from './components/inputs/core/color/color.component'
import { BooleanInputComponent } from './components/inputs/core/boolean/boolean.component'
import { PreviousRendersComponent } from './components/dashboard/previous-renders/previous-renders.component'
import { NgxTippyModule } from 'ngx-tippy-wrapper'
import { SidebarLayoutComponent } from './layouts/sidebar-layout/sidebar-layout.component'
import { NavbarComponent } from './components/navbar/navbar.component'
import { SidebarComponent } from './components/sidebar/sidebar.component'
import { DashboardPanelComponent } from './components/sidebar/dashboard-panel/dashboard-panel.component'
import { VideoPanelComponent } from './components/sidebar/video-panel/video-panel.component'
import { AutomationPanelComponent } from './components/sidebar/automation-panel/automation-panel.component'
import { OrganisationsSelectorComponent } from './components/navbar/organisations-selector/organisations-selector.component'
import { OverlayModule } from '@angular/cdk/overlay'
import { CreateOrganisationOverlayComponent } from './components/create-organisation-overlay/create-organisation-overlay.component'
import { SettingsPanelComponent } from './components/sidebar/settings-panel/settings-panel.component'
import { PortalModule } from '@angular/cdk/portal'
import { EditVideoPreviewComponent } from './components/edit-video-preview/edit-video-preview.component'
import { VideoPreviewComponent } from './components/create-video-modal/video-preview/video-preview.component'
import { TemplateDetailsComponent } from './components/create-video-modal/template-details/template-details.component'
import { StatisticsPanelComponent } from './components/sidebar/statistics-panel/statistics-panel.component'
import { RecentVideosComponent } from './components/recent-videos/recent-videos.component'
import { RenderPreviewDialogComponent } from './dialogs/render-preview-dialog/render-preview-dialog.component'
import { ProcessingMinutesComponent } from './components/dashboard/processing-minutes/processing-minutes.component'
import { NgApexchartsModule } from 'ng-apexcharts'
import { A11yModule } from '@angular/cdk/a11y'
import { LoadingBarComponent } from '../ui/loading-bar/loading-bar.component'
import { DoubleSliderComponent } from '../ui/double-slider/double-slider.component'
import { ToggleSliderComponent } from '../ui/toggle-slider/toggle-slider.component'
import { AiCreationComponent } from './components/ai-creation/ai-creation.component'
import { StepperComponent } from '../ui/stepper/stepper.component'
import { CreateVideoStepDirective } from './directives/create-video-step.directive'
import { AdminPanelComponent } from './components/sidebar/admin-panel/admin-panel.component'
import { StatisticsModule } from '../statistics/statistics.module'
import { ProcessingMinutesUsageChartComponent } from '../statistics/components/charts/processing-minutes-usage-chart/processing-minutes-usage-chart.component'

@NgModule({
  declarations: [
    NotFoundPageComponent,
    DashboardPageComponent,
    CreateVideoModalComponent,
    YouTubeInputComponent,
    CustomComponentDetailFactoryDirective,
    YouTubeDetailComponent,
    WatermarkDetailComponent,
    TextInputComponent,
    NumberInputComponent,
    ColorInputComponent,
    BooleanInputComponent,
    PreviousRendersComponent,
    SidebarLayoutComponent,
    NavbarComponent,
    CreateOrganisationOverlayComponent,
    EditVideoPreviewComponent,
    VideoPreviewComponent,
    TemplateDetailsComponent,
    RenderPreviewDialogComponent,
    ProcessingMinutesComponent,
  ],
  imports: [
    CommonModule,
    AuthModule,
    UiModule,
    AppRoutingModule,
    RouterModule,
    ReactiveFormsModule,
    NgxTippyModule,
    OverlayModule,
    PortalModule,
    NgApexchartsModule,
    A11yModule,
    LoadingBarComponent,
    DoubleSliderComponent,
    ToggleSliderComponent,
    AiCreationComponent,
    StepperComponent,
    CreateVideoStepDirective,
    AdminPanelComponent,
    RecentVideosComponent,
    RecomendedTemplatesComponent,
    SidebarComponent,
    ProcessingMinutesUsageChartComponent,
  ],
})
export class MainModule {}
