import { Overlay } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, input, OnInit, Output, signal } from '@angular/core'
import { RouterModule } from '@angular/router'
import { catchError, Observable, of, tap } from 'rxjs'
import { PaginatedResponse } from 'src/app/main/interfaces/paginated-response'
import { Project } from 'src/app/main/interfaces/project'
import { Render } from 'src/app/main/interfaces/render'
import { OrganisationService } from 'src/app/main/services/organisation/organisation.service'
import { VideoService } from 'src/app/main/services/video.service'
import { IconModule } from 'src/app/ui/icon/icon.module'

interface ProjectFetchResponse {
  response: PaginatedResponse<Project> | null
  page: number
}

@Component({
  selector: 'app-project-list-layout',
  standalone: true,
  imports: [IconModule, RouterModule, CommonModule],
  templateUrl: './project-list-layout.component.html',
  styleUrl: './project-list-layout.component.scss',
})
export class ProjectListLayoutComponent implements OnInit {
  constructor(
    private videoService: VideoService,
    private organisationService: OrganisationService,
    private overlay: Overlay
  ) {}

  public readonly perPage = input<number>(10)
  public readonly showPagination = input<boolean>(true)
  private readonly page = signal<number>(1)

  public readonly refreshObservable = input<Observable<void>>()

  @Output()
  public updated = new EventEmitter<ProjectFetchResponse>()

  protected loading = false
  protected recentProjects: Project[] = []
  protected response: PaginatedResponse<Project> | null = null
  protected cachedOrganisationUuid: string | null = null
  protected error = false

  ngOnInit(): void {
    this.fetchProjects()

    const refreshObservable = this.refreshObservable()
    if (refreshObservable) refreshObservable.subscribe(() => this.fetchProjects())
  }

  protected getDateString(created: string): string {
    const CREATED_NOW_BUFFER = 60
    const isCreatedNow = new Date(created).getTime() > Date.now() - CREATED_NOW_BUFFER * 1000
    if (isCreatedNow) return 'just now'

    const minutes = Math.floor((Date.now() - new Date(created).getTime()) / 60000)
    if (minutes < 60) return `${minutes} minutes ago`

    const hours = Math.floor(minutes / 60)
    if (hours < 24) return `${hours} hours ago`

    const days = Math.floor(hours / 24)
    if (days < 7) return `${days} days ago`

    return new Date(created).toLocaleDateString()
  }

  protected openVideoPreviewDialog(render: Render): void {
    if (!render.output) return

    this.videoService.openVideoPreviewDialog(render)
  }

  private fetchProjects(): void {
    this.organisationService.getActiveOrganisation().subscribe(organisation => {
      this.error = false
      if (organisation?.uuid === this.cachedOrganisationUuid) return
      this.loading = true
      this.recentProjects = []

      if (!organisation) return

      this.videoService
        .listProjects({ organisation: organisation.uuid, perPage: this.perPage(), page: this.page() })
        .pipe(
          catchError(() => {
            this.error = true
            this.loading = false
            return of(null)
          }),
          tap(() => (this.loading = false))
        )
        .subscribe(projects => {
          if (!projects) {
            this.response = null
          } else {
            this.response = projects
            this.recentProjects = projects.results
          }

          this.updated.emit({ response: this.response, page: this.page() })
        })
    })
  }

  protected nextPage(): void {
    this.page.set(this.page() + 1)
    this.fetchProjects()
  }

  protected previousPage(): void {
    this.page.set(this.page() - 1)
    this.fetchProjects()
  }
}
