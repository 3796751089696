import { Component, Input } from '@angular/core'
import { SidebarPanel } from '../sidebar.component'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-settings-panel',
  templateUrl: './settings-panel.component.html',
  styleUrls: ['./settings-panel.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SettingsPanelComponent {
  @Input()
  public activePanel: SidebarPanel | null = null
}
