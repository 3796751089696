import { CommonModule } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { RouterModule } from '@angular/router'
import { Observable, catchError, switchMap, tap, timer } from 'rxjs'
import { MinimalVideo } from 'src/app/main/interfaces/minimal.video'
import { MinimalOrganisation } from 'src/app/main/interfaces/organisation.interface'
import { PaginatedResponse } from 'src/app/main/interfaces/paginated-response'
import { OrganisationService } from 'src/app/main/services/organisation/organisation.service'
import { VideoService } from 'src/app/main/services/video.service'
import { CardModule } from 'src/app/ui/card/card.module'
import { IconModule } from 'src/app/ui/icon/icon.module'
import { TemplateCardModule } from 'src/app/ui/template-card/template-card.module'

@Component({
  selector: 'main-recomended-templates',
  templateUrl: './recomended-templates.component.html',
  styleUrls: ['./recomended-templates.component.scss'],
  standalone: true,
  imports: [TemplateCardModule, IconModule, CommonModule, CardModule, RouterModule],
})
export class RecomendedTemplatesComponent implements OnInit, AfterViewInit {
  constructor(
    private videoService: VideoService,
    private organisationService: OrganisationService,
    private cdr: ChangeDetectorRef
  ) {}

  @ViewChild('container')
  public container: ElementRef<HTMLDivElement> | null = null

  @ViewChild('templateContainer')
  public templateContainer: ElementRef<HTMLDivElement> | null = null

  public readonly showLinkArrow = input(false)

  protected templates: PaginatedResponse<MinimalVideo> | null = null
  protected isError = false
  protected isLoading = false

  private previousOrganisationUuid: string | null = null

  ngOnInit(): void {
    // this.organisationService.getActiveOrganisation().subscribe(this.resetOrganisationSettings.bind(this))

    window.addEventListener('resize', this.onScroll.bind(this))

    this.getTemplates()
  }

  private getTemplates() {
    this.organisationService.getActiveOrganisation().subscribe(organisation => {
      this.isError = false
      if (organisation?.uuid === this.previousOrganisationUuid) return

      this.isLoading = true
      this.templates = null

      this.previousOrganisationUuid = organisation?.uuid || null

      if (!organisation) return

      this.videoService
        .listTemplates(organisation.uuid)
        .pipe(
          catchError(() => {
            this.isError = true
            this.isLoading = false
            return []
          }),
          tap(() => (this.isLoading = false))
        )
        .subscribe(templates => {
          this.templates = templates

          timer(0).subscribe(() => {
            this.onScroll()
          })
        })
    })
  }

  protected onScroll() {
    if (this.container && this.templateContainer) {
      this.showNextClickButton =
        this.templateContainer.nativeElement.scrollWidth - 12 > this.templateContainer.nativeElement.clientWidth &&
        this.templateContainer.nativeElement.scrollLeft <=
          this.templateContainer.nativeElement.scrollWidth - this.templateContainer.nativeElement.clientWidth - 12

      this.showPreviousClickButton =
        this.templateContainer.nativeElement.scrollLeft > 0 &&
        this.templateContainer.nativeElement.scrollWidth > this.templateContainer.nativeElement.clientWidth

      this.cdr.detectChanges()
    }
  }

  protected onNextClick() {
    if (this.templateContainer) {
      this.templateContainer.nativeElement.scrollTo({
        left: this.templateContainer.nativeElement.scrollLeft + 200,
        behavior: 'smooth',
      })
    }
  }

  protected onPreviousClick() {
    if (this.templateContainer) {
      this.templateContainer.nativeElement.scrollTo({
        left: this.templateContainer.nativeElement.scrollLeft - 200,
        behavior: 'smooth',
      })
    }
  }

  protected showNextClickButton = false
  protected showPreviousClickButton = false

  ngAfterViewInit(): void {
    this.onScroll()
  }
}
