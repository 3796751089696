import { Component, Input } from '@angular/core'
import { SidebarPanel } from '../sidebar.component'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-automation-panel',
  templateUrl: './automation-panel.component.html',
  styleUrls: ['./automation-panel.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class AutomationPanelComponent {
  @Input()
  public activePanel: SidebarPanel | null = null
}
