<div class="flex items-center">
  <h1 class="text-darker grow font-semibold text-lg">General information</h1>
  <button
    class="button-primary text-sm ml-auto w-16 flex items-center justify-center relative"
    [disabled]="!isGeneralFormValid() || isSavingGeneralForm()"
    (click)="saveGeneralForm()">
    <span
      [ngClass]="{ hidden: !isSavingGeneralForm() }"
      class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
      <svg
        class="animate-spin h-4 w-4 text-dark opacity-50"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle class="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </span>
    <span [ngClass]="{ 'opacity-0': isSavingGeneralForm() }"> Save </span>
  </button>
</div>
<form class="grid grid-cols-2 max-w-sm gap-4" [formGroup]="generalForm">
  <div>
    <h6 class="text-xs uppercase tracking-widest font-light mt-4 text-gray-400 mb-1">First name</h6>
    <input
      type="text"
      class="input px-1 py-1 text-sm w-full block text-dark"
      placeholder="John"
      formControlName="firstName" />
  </div>
  <div>
    <h6 class="text-xs uppercase tracking-widest font-light mt-4 text-gray-400 mb-1">Last name</h6>
    <input
      type="text"
      class="input px-1 py-1 text-sm w-full block text-dark"
      placeholder="Smith"
      formControlName="lastName" />
  </div>
  <div class="col-span-2">
    <h6 class="text-xs uppercase tracking-widest font-light text-gray-400 mb-1">Email</h6>
    <input
      type="email"
      class="input px-1 py-1 text-sm w-full block text-neutral-500 bg-neutral-100 cursor-not-allowed"
      placeholder="john@cliphog.com"
      formControlName="email"
      [disabled]="true" />
    <div class="text-xs text-neutral-500 mt-2">If you need to change your email address, please contact support.</div>
  </div>
</form>
