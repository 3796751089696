import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'bytes',
  standalone: true,
})
export class BytesToStringPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    // Convert bytes to human-readable string
    if (value === 0) {
      return '0B'
    }

    const k = 1024
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(value) / Math.log(k))

    return parseFloat((value / Math.pow(k, i)).toFixed(2)) + sizes[i]
  }
}
