import { CommonModule } from '@angular/common'
import { Component, computed, input, OnChanges, OnInit, signal, Signal, SimpleChanges } from '@angular/core'
import { ApexChart, ApexStroke, NgApexchartsModule } from 'ng-apexcharts'
import { switchMap } from 'rxjs'
import {
  BaseAnalyticResponse,
  ProcessingLimitStatisticResponse,
} from 'src/app/main/services/organisation/organisation-api.service'
import { OrganisationService } from 'src/app/main/services/organisation/organisation.service'

type ChartOptions = {
  series: ApexAxisChartSeries
  chart: ApexChart
  xaxis: ApexXAxis
  yaxis: ApexYAxis
  title: ApexTitleSubtitle
  dataLabels: ApexDataLabels
  stroke: ApexStroke
  plotOptions: ApexPlotOptions
  tooltip: ApexTooltip
  states: ApexStates
}

@Component({
  selector: 'app-video-creation-chart',
  standalone: true,
  imports: [NgApexchartsModule, CommonModule],
  templateUrl: './video-creation-chart.component.html',
  styleUrl: './video-creation-chart.component.scss',
})
export class VideoCreationChartComponent implements OnInit, OnChanges {
  constructor(private organisationService: OrganisationService) {}

  public month = input.required<number>()
  public year = input.required<number>()
  public height = input<number>(350)

  public data = input<BaseAnalyticResponse>()

  protected chartOptions: Signal<Partial<ChartOptions>> = computed(() => {
    const series = this.chartSeriesData()
    let yAxis
    if (series.filter(s => s.data.filter(d => d > 0).length > 0).length === 0) {
      yAxis = {
        stepSize: 1,
      }
    }

    return {
      chart: {
        type: 'line',
        height: this.height(),
        toolbar: {
          show: false,
          autoSelected: 'pan',
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 500,
        },
      },
      xaxis: {
        type: 'datetime',
        categories: this.xAxisCategories(),
      },
      series: this.chartSeriesData(),
      yaxis: yAxis,
      // title: {
      //   text: `Processing Minute usage for ${this.organisationName()} in ${this.monthAsStr()} ${this.year()}`,
      //   align: 'left',
      // },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
        colors: ['#A2D1FD'],
        width: 3,
      },
    }
  })

  protected videoCreationData = signal<BaseAnalyticResponse | null>(null)
  protected chartSeriesData = computed(() => {
    const data = this.videoCreationData()

    if (!data) {
      return []
    }

    // we need to mutate the data to get n + 1 different arrays, where n is the number of users in the org.
    // the arrays should be:
    // 0: the total processing minutes
    // n: the processing minutes for the nth user

    const totalDataObj: number[] = []
    // const userDatas: {
    //   data: number[]
    // }[] = []
    const xaxisCategories = this.xAxisCategories()
    const dataWithDateAnnotations: Record<string, number> = {}
    xaxisCategories.forEach(category => {
      dataWithDateAnnotations[category] = 0
    })

    for (const key of Object.keys(data)) {
      const userData = data[key]

      userData.forEach((dataPoint, i) => {
        const date = dataPoint.label
        console.log(date)
        dataWithDateAnnotations[date] = dataWithDateAnnotations[date] + dataPoint.value
      })
    }

    // now, set totalDataObj to the values of dataWithDateAnnotations
    totalDataObj.push(...Object.values(dataWithDateAnnotations))

    return [
      {
        name: 'Total',
        data: totalDataObj,
        color: '#A2D1FD',
      },
      // ...userDatas.map(({ name, data }) => {
      //   return {
      //     name,
      //     data,
      //   }
      // }),
    ]
  })

  protected xAxisCategories = computed(() => {
    const data = this.videoCreationData()

    if (!data) {
      return []
    }

    // the server doesn't send over all of the days in the month, so we need to fill in the gaps
    // if the month is the current month, we can assume that the month is not over, so we can use the current day
    // as the last day in the month
    let daysInMonth = new Date(this.year(), this.month(), 0).getDate()
    if (this.year() === new Date().getFullYear() && this.month() === new Date().getMonth() + 1) {
      daysInMonth = new Date().getDate()
    }
    const categories = Array.from({ length: daysInMonth }, (_, i) => i + 1) // 1-31/30/28/29 whatever it is.

    return categories.map(
      day => `${this.year()}-${this.month().toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
    )
  })

  protected videoCreationData$ = this.organisationService.getActiveOrganisation().pipe(
    switchMap(organisation => {
      if (!organisation) {
        return []
      }

      return this.organisationService.getVideoCreationStats(organisation.uuid, {
        month: this.month(),
        year: this.year(),
      })
    })
  )

  protected secondsToMinutes(seconds: number): number {
    return Math.ceil(seconds / 60)
  }

  ngOnInit(): void {
    const data = this.data()

    if (data) {
      this.videoCreationData.set(data)
      return
    }

    this.videoCreationData$.subscribe(data => {
      this.videoCreationData.set(data)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    const dataChange = changes['data']
    if (dataChange) {
      this.videoCreationData.set(dataChange.currentValue)
    }
  }

  // private yAxis = signal<ApexYAxis | undefined>(undefined)
  private yAxis: ApexYAxis | undefined = undefined
}
