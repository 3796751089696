<div class="-mt-4">
  <div class="transition-all overflow-y-auto">
    <div class="flex mt-4">
      <div class="grow">
        <h1 class="text-2xl font-bold text-dark flex gap-1 items-center">Add captions</h1>
        <p class="font-light text-sm text-neutral-500">Add captions to the template.</p>
      </div>
      <div class="w-24 shrink-0 flex items-end justify-center flex-col gap-1 -mt-2">
        <ui-component-layout-preview
          [component]="getDummySubtitleComponentForLayout()"
          [template]="template"
          class="h-20" />
      </div>
    </div>
    <form class="mt-4 flex flex-col gap-2" [formGroup]="form">
      <div class="flex gap-2 items-center">
        <ui-toggle-slider
          (toggledChange)="onSubtitleChange($event)"
          [toggled]="form.controls.enabled.value"
          class="mt-2" />
        <p class="text-sm text-dark mt-2 cursor-default" (click)="onSubtitleChange(!form.controls.enabled.value)">
          Captions {{ form.controls.enabled.value ? 'enabled' : 'disabled' }}
        </p>
      </div>
    </form>
    <button class="w-full button-primary mt-8 flex items-center justify-center gap-2" (click)="nextStep()">
      Next step
    </button>
  </div>
</div>
