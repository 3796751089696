@if (showDialog()) {
<ui-card
  [@enterFromBottom]
  (@enterFromBottom.done)="attemptCloseDialog()"
  class="p-6 max-w-xl w-screen max-h-screen relative transition-all delay-[250ms]">
  <div class="top-2 right-2 absolute">
    <button
      class="text-xl flex items-center gap-2 p-1 rounded-full hover:bg-neutral-100 transition-colors"
      (click)="_closeOverlay()">
      <ng-icon name="heroXMark" class="w-4 h-4" />
    </button>
  </div>
  <div class="flex flex-col h-full w-full relative">
    <div class="px-2 grow block overflow-y-auto">
      <h1 class="text-2xl font-extrabold text-darker mb-3">Upgrade to Premium</h1>
      <p class="text-neutral-500 mb-4 font-medium">
        Upgrade to a premium plan today, for just $9.99/month, and gain access to:
      </p>
      <div class="flex flex-col gap-4 ml-4">
        <div class="flex gap-2 items-center">
          <ng-icon name="heroCheckCircleSolid" class="text-xl text-mid" />
          <p class="font-medium text-dark">4 processing hours per month</p>
        </div>
        <div class="flex gap-2 items-center">
          <ng-icon name="heroCheckCircleSolid" class="text-xl text-mid" />
          <p class="font-medium text-dark">No watermark on your videos</p>
        </div>
        <div class="flex gap-2 items-center">
          <ng-icon name="heroCheckCircleSolid" class="text-xl text-mid" />
          <p class="font-medium text-dark">Unlimited organizations</p>
        </div>
        <div class="flex gap-2 items-center">
          <ng-icon name="heroCheckCircleSolid" class="text-xl text-mid" />
          <p class="font-medium text-dark">Priority support</p>
        </div>
        <div class="text-xs text-neutral-500">
          <p>...and more to come!</p>
        </div>
      </div>
    </div>
  </div>
  <div class="h-px bg-neutral-100 my-6"></div>
  <div class="flex flex-col items-center justify-center">
    <div class="text-xs text-neutral-400 mb-3">You will be redirected to Stripe to complete the payment.</div>
    <button
      (click)="loadStripeCheckout()"
      class="rounded-lg w-full h-12 bg-amber-100 hover:bg-amber-200 transition-all flex items-center justify-center gap-2 block relative disabled:bg-neutral-200"
      [disabled]="loadingStripeCheckout()">
      <span
        [ngClass]="{ hidden: !loadingStripeCheckout() }"
        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <svg
          class="animate-spin h-4 w-4 text-dark opacity-50"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24">
          <circle class="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </span>
      <ng-icon
        [ngClass]="{ 'opacity-0': loadingStripeCheckout() }"
        name="heroGiftSolid"
        class="text-yellow-600 text-xl" />
      <span [ngClass]="{ 'opacity-0': loadingStripeCheckout() }" class="font-semibold text-yellow-800">
        Go Premium for $9.99/month
      </span>
    </button>
  </div>
</ui-card>
}
