<ng-container *ngIf="video">
  <button
    *ngIf="video.previewRender?.previewThumbnail as thumbnail; else noThumbnail"
    [ngClass]="{ ' hover:ring-2 hover:ring-brand-transparent cursor-pointer': takeInputs }"
    class="ring-0 rounded-xl h-full overflow-hidden relative block card-focus-class"
    [ngStyle]="{ 'width.px': videoWidth }"
    (focus)="playVideo()"
    (blur)="attemptStopVideo()"
    [attr.tabindex]="takeInputs ? 0 : -1">
    <video
      #videoElement
      class="h-full w-full"
      [ngClass]="{ 'cursor-default': !takeInputs }"
      [src]="video.previewRender?.output"
      [muted]="'muted'"
      preload="auto"
      playsInline="true"
      [poster]="video.previewRender?.previewThumbnail"
      (loadedmetadata)="onLoadedMetadata()"
      (ended)="videoState = 'ended'"
      (mouseover)="playVideo()"
      (mouseleave)="attemptStopVideo()"></video>
    <div
      *ngIf="videoDuration && showDuration"
      class="absolute bottom-3 left-3 text-white text-xs bg-neutral-800 p-0.5 rounded px-1">
      {{ videoDuration * 1000 | date: 'mm:ss' }}
    </div>
    <div
      *ngIf="videoState === 'ended'"
      [@fadeInOut]
      class="absolute inset-0 flex justify-center items-center bg-neutral-800/25 backdrop-blur-sm backdrop-saturate-[0.25] text-white font-semibold text-sm">
      Lorem Ipsum
    </div>
  </button>
  <ng-template #noThumbnail>
    <!-- placeholder should use the video's metadata to extract the aspect ratio -->
    <button
      class="rounded-xl bg-gray-200 h-full flex flex-col gap-1 items-center justify-center card-focus-class"
      [ngStyle]="{ 'aspect-ratio': video.metadata.width + '/' + video.metadata.height }"
      [ngClass]="{ 'cusor-pointer': showPointerIfNoPreview, 'cursor-default': !showPointerIfNoPreview }"
      [attr.tabindex]="takeInputs ? 0 : -1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        fill="currentColor"
        class="w-8 text-light"
        viewBox="0 0 520 520">
        <g>
          <path
            d="M505.29,157.622c-9.005-5.568-20.585-6.075-30.037-1.342L397,195.244v-42.185c0-16.862-13.256-30.136-30.118-30.136   H183.734l-68.365-80.99c-10.883-12.866-30.131-14.591-43.027-3.685C59.476,49.14,57.866,68.36,68.764,81.233l335.867,396.909   c6.038,7.134,14.641,10.797,23.318,10.797c6.962,0,13.97-2.377,19.71-7.23c12.866-10.891,14.276-30.164,3.378-43.038L397,375.045   v-19.903l78.136,38.964c4.309,2.154,9.098,3.22,13.764,3.22c5.576,0,11.435-1.528,16.34-4.562   c8.99-5.561,14.76-15.386,14.76-25.971v-183.2C520,173.007,514.28,163.183,505.29,157.622z" />
          <path
            d="M0,153.059v244.267c0,16.862,14.138,30.597,31,30.597h260.756L29.879,122.647C13.443,123.128,0,136.499,0,153.059z" />
        </g>
      </svg>
      <span class="text-xs text-dark">Preview not available</span>
    </button>
  </ng-template>
</ng-container>
