import { Component, computed, ElementRef, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core'
import { FileUploadComponent } from 'src/app/ui/file-upload/file-upload.component'
import { ToastService } from 'src/app/ui/toast/toast.service'

@Component({
  selector: 'app-action-video-source-file',
  standalone: true,
  imports: [FileUploadComponent],
  templateUrl: './action-video-source-file.component.html',
  styleUrl: './action-video-source-file.component.scss',
})
export class ActionVideoSourceFileComponent {
  constructor(private toast: ToastService) {}

  public MAX_FILE_SIZE = 1024 * 1024 * 512

  @Output()
  public videoDurationChanged = new EventEmitter<number>()

  @Output()
  public videoFileUploaded = new EventEmitter<File>()

  @ViewChild('video')
  protected video: ElementRef<HTMLVideoElement> | null = null

  private previousSubclipData: number[] = [0, 0]

  @Input()
  public set subclipData(_subclipData: number[]) {
    const [subclipStart, subclipEnd] = _subclipData
    if (this.previousSubclipData[0] === subclipStart && this.previousSubclipData[1] === subclipEnd) {
      return
    }

    this.previousSubclipData = _subclipData

    if (this.video) {
      this.video.nativeElement.currentTime = subclipStart
      this.video.nativeElement.play()
      if (this.videoDurationTimeout) {
        clearTimeout(this.videoDurationTimeout)
      }

      this.videoDurationTimeout = setTimeout(
        () => {
          if (!this.video) return

          const currentVideoTime = this.video.nativeElement.currentTime
          this.video.nativeElement.pause()
          this.video.nativeElement.currentTime = currentVideoTime
        },
        (subclipEnd - subclipStart) * 1000
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private videoDurationTimeout: any = null // NodeJS.Timeout

  protected file = signal<File | null>(null)

  protected onFileUploaded(file: File) {
    if (file.size > this.MAX_FILE_SIZE) {
      this.toast.error('File size too big - try a smaller one!')
      return
    }
    this.file.set(file)
    this.videoFileUploaded.emit(file)
  }

  protected fileUrl = computed(() => {
    const file = this.file()
    return file ? URL.createObjectURL(file) : null
  })

  protected onLoadedMetadata(event: Event) {
    const target = event.target as HTMLVideoElement
    this.videoDurationChanged.emit(Math.round(target.duration))
  }
}
