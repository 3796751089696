<div class="overflow-hidden w-full rounded">
  @if (processingMinutesData(); as data) {
  <apx-chart
    [series]="chartOptions().series!"
    [chart]="chartOptions().chart!"
    [plotOptions]="chartOptions().plotOptions!"
    [tooltip]="chartOptions().tooltip!"
    [states]="chartOptions().states!"
    [xaxis]="chartOptions().xaxis!" />
  }
</div>
