import { Component, computed, EventEmitter, Input, input, OnInit, Output, signal } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { YouTubePlayer } from 'youtube-player/dist/types'
import YoutubePlayer from 'youtube-player'
import { catchError, from, switchMap, tap } from 'rxjs'
import { toObservable } from '@angular/core/rxjs-interop'

export const YOUTUBE_REGEX = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/

@Component({
  selector: 'app-action-video-source-youtube',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './action-video-source-youtube.component.html',
  styleUrl: './action-video-source-youtube.component.scss',
})
export class ActionVideoSourceYoutubeComponent implements OnInit {
  @Output()
  public videoDurationChanged = new EventEmitter<number>()

  @Output()
  public urlChanged = new EventEmitter<string>()

  protected urlControl = new FormControl('')

  @Input()
  public set subclipData(value: number[]) {
    this._subclipData.set(value)
    this.updateEmbed()
  }

  protected _subclipData = signal<number[]>([0, 60])
  protected selectedVideoDuration = 0

  private player?: YouTubePlayer

  ngOnInit(): void {
    this.urlControl.valueChanges.subscribe(url => {
      if (YOUTUBE_REGEX.test(url!)) {
        this.attemptInitializePlayer(url!)
        return
      }

      this.urlChanged.emit('')

      this.player?.destroy()
      this.player = undefined
    })
  }

  private attemptInitializePlayer(url: string): void {
    if (!this.player) {
      this.player = YoutubePlayer('youtube-player', {
        playerVars: {
          autoplay: 1,
          controls: 0,
          disablekb: 1,
          enablejsapi: 1,
          fs: 0,
          loop: 1,
          modestbranding: 1,
          playsinline: 1,
          rel: 0,
        },
        height: '140',
      })
    }

    const [subclipStart, subclipEnd] = this._subclipData()

    from(this.player.loadVideoById(this.getIdFromYouTubeUrl(url)!))
      .pipe(switchMap(() => from(this.player!.seekTo(subclipStart, false))))
      .subscribe()

    this.player.on('stateChange', event => {
      if (event.data === 1) {
        this.player
          ?.playVideo()
          .then(() => this.player?.getDuration())
          .then(duration => {
            if (!duration) return

            this.selectedVideoDuration = Math.floor(duration)
            this.videoDurationChanged.emit(this.selectedVideoDuration)
            this.urlChanged.emit(url) // stupid. but it doesnt matter for now because useris blocked unless we get here anyway.
          })
      }
    })
  }

  private previousStartTime: number | undefined
  private previousEndTime: number | undefined

  private getIdFromYouTubeUrl(url: string): string | null {
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/)
    if (!match) {
      return null
    }

    // don't include ?
    const queryIndex = match[1].indexOf('?')
    if (queryIndex !== -1) {
      return match[1].substring(0, queryIndex)
    }

    return match[1]
  }

  private updateEmbed() {
    const url = this.urlControl.value
    const [subclipStart, subclipEnd] = this._subclipData()

    const id = this.getIdFromYouTubeUrl(url ?? '')

    if (!id || subclipStart === undefined || subclipEnd === undefined || subclipStart >= subclipEnd) return

    if (!this.player) {
      this.player = YoutubePlayer('youtube-player', {
        playerVars: {
          autoplay: 1,
          controls: 0,
          disablekb: 1,
          enablejsapi: 1,
          fs: 0,
          loop: 1,
          modestbranding: 1,
          playsinline: 1,
          rel: 0,
          // origin: window.location.origin,
        },
        height: '200',
      })

      this.player
        .loadVideoById({
          videoId: id,
          startSeconds: subclipStart,
          endSeconds: subclipEnd,
        })
        .then(() => {
          this.player?.seekTo(subclipStart, false)
        })

      this.player.on('stateChange', event => {
        if (event.data === 1) {
          this.player
            ?.playVideo()
            .then(() => this.player?.getDuration())
            .then(duration => {
              if (!duration) return

              const [subclipStart] = this._subclipData()

              this.selectedVideoDuration = Math.floor(duration)
              this.videoDurationChanged.emit(this.selectedVideoDuration)
            })
        }
      })
    } else {
      if (this.previousStartTime !== subclipStart) {
        this.player.seekTo(subclipStart, true)
        this.previousStartTime = subclipStart
      }

      if (this.previousEndTime !== subclipEnd) {
        this.previousEndTime = subclipEnd
        // all this timeout stuff is goofy and doesn't work. we'll just-requeue the video
        this.player?.loadVideoById({
          videoId: id,
          startSeconds: subclipStart,
          endSeconds: subclipEnd,
        })
      }
    }
  }
}
