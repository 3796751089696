<form class="mt-4 flex flex-col gap-2 p-2" [formGroup]="form">
  <!-- <div
    [@animateHeight]
    *ngIf="form.controls.subclipMode.value === 'manual' && selectedVideoDuration"
    class="overflow-hidden transition-all">
    <div class="w-full pr-16 px-1">
      <ui-double-slider
        #slider
        class="w-full"
        [min]="0"
        [max]="selectedVideoDuration"
        [step]="1"
        [value]="[form.get('subclipStart')?.value ?? 0, form.get('subclipEnd')?.value ?? 0]"
        [maxRange]="300"
        (valueChange)="onSliderChange($event)" />
    </div> -->
  <div>
    <div class="text-xs text-neutral-500 font-light text-left mb-1">Video scale method</div>
    <div>
      <select formControlName="scaleType" class="input px-1 py-1 text-sm w-fit block text-dark">
        <option value="contain-blur">Contain and Blur</option>
        <option value="contain">Contain</option>
        <option value="stretch">Stretch</option>
      </select>
    </div>
    <div class="mt-4 text-xs text-neutral-400 flex gap-2 items-center">
      <div class="">
        <ng-icon name="heroInformationCircle" class="text-neutral-400" size="1.25rem" />
      </div>
      <ng-container *ngIf="form.controls.scaleType.value === 'contain-blur'">
        <div>
          <p>
            The video will keep its original aspect ratio and any extra space will be filled with a blurred version of
            the video.
          </p>
          <p *ngIf="templateComponentCount() === 1" class="text-mid">This option works best for this template.</p>
        </div>
      </ng-container>
      <ng-container *ngIf="form.controls.scaleType.value === 'contain'">
        The video will keep its original aspect ratio and any extra space won't be filled in - leaving a black border.
      </ng-container>
      <ng-container *ngIf="form.controls.scaleType.value === 'stretch'">
        <div>
          <p>The video will stretch to fill the entire space, potentially cutting off parts of the video.</p>
          <p *ngIf="templateComponentCount() === 2" class="text-mid">
            This option usually works best for this template.
          </p>
        </div>
      </ng-container>
    </div>
    <div *ngIf="showMute()" class="mt-6 flex gap-2 items-center">
      <input type="checkbox" #muted formControlName="muted" class="input" />
      <label class="text-xs text-neutral-500 font-light text-left" (click)="muted.click()">Mute video</label>
    </div>
    <ng-container *ngIf="subclipMode() === 'auto'">
      <p class="font-semibold text-dark mb-2 mt-4">AI settings</p>
      <p class="text-xs text-neutral-500 font-light text-left mb-1">Desired clip theme</p>
      <textarea
        class="input text-sm px-1 py-0.5 w-full"
        [formControl]="form.controls.clipTheme"
        placeholder="The clips should be comedic, motivational, etc. (max 100 characters)"
        rows="3"
        maxlength="100"
        style="resize: none"></textarea>
    </ng-container>
  </div>
</form>
