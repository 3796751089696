import { CommonModule } from '@angular/common'
import { ChangeDetectorRef, Component, EventEmitter, input, Input, OnInit, Output } from '@angular/core'
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { catchError, from, map, switchMap, tap } from 'rxjs'
import { VideoService } from 'src/app/main/services/video.service'
import { DoubleSliderComponent } from 'src/app/ui/double-slider/double-slider.component'
import YoutubePlayer from 'youtube-player'
import { YouTubePlayer } from 'youtube-player/dist/types'
import { MAX_VIDEO_LENGTH, VideoType, YOUTUBE_REGEX } from '../create-video-action-add-video.component'
import { Video } from 'src/app/main/interfaces/video.interface'
import { VideoComponentV0_0_0 } from 'src/app/main/interfaces/template/v0_0_0'
import { animate, style, transition, trigger } from '@angular/animations'
import { ActionVideoSourceYoutubeComponent } from './action-video-source-youtube/action-video-source-youtube.component'
import { ActionVideoSourceFileComponent } from './action-video-source-file/action-video-source-file.component'

@Component({
  selector: 'app-action-set-video-source',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DoubleSliderComponent,
    CommonModule,
    ActionVideoSourceYoutubeComponent,
    ActionVideoSourceFileComponent,
  ],
  templateUrl: './action-set-video-source.component.html',
  styleUrl: './action-set-video-source.component.scss',
  animations: [
    trigger('animateHeight', [
      transition(':enter', [style({ height: 0 }), animate('0.15s', style({ height: '*' }))]),
      transition(':leave', [style({ height: '*' }), animate('0.15s', style({ height: 0 }))]),
    ]),
  ],
})
export class ActionSetVideoSourceComponent implements OnInit {
  constructor(
    private fb: NonNullableFormBuilder,
    private videoService: VideoService,
    private cdr: ChangeDetectorRef
  ) {}

  @Input({ required: true })
  public component!: VideoComponentV0_0_0

  @Input({ required: true })
  public template!: Video

  @Output()
  public videoTypeChanged = new EventEmitter<VideoType>()

  protected form = this.fb.group({
    videoType: ['youtube', [Validators.required]],
    url: ['', [Validators.required]],
    file: [null as File | null], // not required. could be youtube.
    subclipMode: ['', [Validators.required]], // "auto" | "manual"
    subclipStart: [0], // not required, as AI could handle it.
    subclipEnd: [60], // ^^^
  })

  protected get subclipData(): number[] {
    return [this.form.controls.subclipStart.value, this.form.controls.subclipEnd.value]
  }

  @Input()
  public set videoType(type: VideoType) {
    this.form.controls.videoType.setValue(type)
  }

  public canChangeVideoType = input(true)

  @Output()
  public formUpdated = this.form.valueChanges.pipe(
    map(value => {
      return {
        ...value,
        subclipMode: value.subclipMode as 'auto' | 'manual',
      }
    })
  )

  @Output()
  public validityChanged = new EventEmitter<'valid' | 'invalid' | 'pending' | 'waiting'>()

  protected selectedVideoDuration = 0
  protected validityState: 'valid' | 'invalid' | 'pending' | 'waiting' = 'waiting'
  private player?: YouTubePlayer

  ngOnInit(): void {
    this.form.controls.url.valueChanges.subscribe(url => {
      if (YOUTUBE_REGEX.test(url!)) {
        this.attemptInitializePlayer(url!)
        return
      }

      this.player?.destroy()
      this.player = undefined
    })

    this.form.controls.url.valueChanges.subscribe(() => this.updateEmbed())
    this.form.controls.subclipStart.valueChanges.subscribe(() => this.updateEmbed())
    this.form.controls.subclipEnd.valueChanges.subscribe(() => this.updateEmbed())

    this.form.controls.videoType.valueChanges.subscribe(type => {
      this.videoTypeChanged.emit(type as VideoType)
    })
  }

  protected onSliderChange(range: [number, number]): void {
    this.form.controls.subclipStart.setValue(range[0])
    this.form.controls.subclipEnd.setValue(range[1])
  }

  private attemptInitializePlayer(url: string): void {
    if (!this.player) {
      this.player = YoutubePlayer('youtube-player', {
        playerVars: {
          autoplay: 1,
          controls: 0,
          disablekb: 1,
          enablejsapi: 1,
          fs: 0,
          loop: 1,
          modestbranding: 1,
          playsinline: 1,
          rel: 0,
        },
        height: '140',
      })
    }
    from(this.player.loadVideoById(this.getIdFromYouTubeUrl(url)!))
      .pipe(
        tap(() => {
          this.validityState = 'pending'
          this.validityChanged.emit('pending')
        }),
        switchMap(() => from(this.player!.seekTo(this.form.controls.subclipStart.value, false))),
        switchMap(() => this.videoService.getVideoUrlSuitableForAi(url)),
        catchError(() => [{ success: false }])
      )
      .subscribe(({ success }) => {
        this.validityState = success ? 'valid' : 'invalid'
        this.validityChanged.emit(this.validityState)
      })

    this.player.on('stateChange', event => {
      if (event.data === 1) {
        this.player
          ?.playVideo()
          .then(() => this.player?.getDuration())
          .then(duration => {
            if (!duration) return

            const { subclipStart } = this.form.getRawValue()

            this.selectedVideoDuration = Math.floor(duration)
            this.form.controls.subclipEnd.setValidators([
              Validators.max(duration),
              Validators.max(subclipStart + MAX_VIDEO_LENGTH),
              Validators.required,
              Validators.min(subclipStart),
            ])

            this.form.controls.subclipEnd.updateValueAndValidity()

            this.cdr.detectChanges()
          })
      }
    })
  }

  private previousStartTime: number | undefined
  private previousEndTime: number | undefined

  private updateEmbed() {
    const { url, subclipStart, subclipEnd } = this.form.getRawValue()

    const id = this.getIdFromYouTubeUrl(url ?? '')

    if (!id || subclipStart === undefined || subclipEnd === undefined || subclipStart >= subclipEnd) return

    if (!this.player) {
      this.player = YoutubePlayer('youtube-player', {
        playerVars: {
          autoplay: 1,
          controls: 0,
          disablekb: 1,
          enablejsapi: 1,
          fs: 0,
          loop: 1,
          modestbranding: 1,
          playsinline: 1,
          rel: 0,
          // origin: window.location.origin,
        },
        height: '200',
      })

      this.player
        .loadVideoById({
          videoId: id,
          startSeconds: subclipStart,
          endSeconds: subclipEnd,
        })
        .then(() => {
          this.player?.seekTo(subclipStart, false)
        })

      this.player.on('stateChange', event => {
        if (event.data === 1) {
          this.player
            ?.playVideo()
            .then(() => this.player?.getDuration())
            .then(duration => {
              if (!duration) return

              const { subclipStart } = this.form.getRawValue()

              this.selectedVideoDuration = Math.floor(duration)
              this.form.controls.subclipEnd.setValidators([
                Validators.max(duration),
                Validators.max(subclipStart + MAX_VIDEO_LENGTH),
                Validators.required,
                Validators.min(subclipStart),
              ])

              this.form.controls.subclipEnd.updateValueAndValidity()

              this.cdr.detectChanges()
            })
        }
      })
    } else {
      if (this.previousStartTime !== subclipStart) {
        this.player.seekTo(subclipStart, true)
        this.previousStartTime = subclipStart
      }

      if (this.previousEndTime !== subclipEnd) {
        this.previousEndTime = subclipEnd
        // all this timeout stuff is goofy and doesn't work. we'll just-requeue the video
        this.player?.loadVideoById({
          videoId: id,
          startSeconds: subclipStart,
          endSeconds: subclipEnd,
        })
      }
    }
  }

  private getIdFromYouTubeUrl(url: string): string | null {
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/)
    if (!match) {
      return null
    }

    // don't include ?
    const queryIndex = match[1].indexOf('?')
    if (queryIndex !== -1) {
      return match[1].substring(0, queryIndex)
    }

    return match[1]
  }

  protected onVideoDurationChange(duration: number): void {
    this.selectedVideoDuration = duration

    this.form.controls.subclipEnd.setValidators([
      Validators.max(duration),
      Validators.max(this.form.controls.subclipStart.value + MAX_VIDEO_LENGTH),
      Validators.required,
      Validators.min(this.form.controls.subclipStart.value),
    ])

    this.form.controls.subclipEnd.updateValueAndValidity()

    this.cdr.detectChanges()
  }

  protected onUrlChange(url: string): void {
    this.form.controls.url.setValue(url)
  }

  protected onFileUploaded(file: File): void {
    this.form.controls.file.setValue(file)
  }
}
