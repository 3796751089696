import { Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Component } from '@angular/core'
import { UpgradePlanDialogComponent } from 'src/app/main/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component'
import { IconModule } from 'src/app/ui/icon/icon.module'

@Component({
  selector: 'app-upgrade-panel',
  standalone: true,
  imports: [IconModule, OverlayModule],
  templateUrl: './upgrade-panel.component.html',
  styleUrl: './upgrade-panel.component.scss',
})
export class UpgradePanelComponent {
  constructor(private overlay: Overlay) {}

  private overlayRef: OverlayRef | null = null

  protected openDialog() {
    if (this.overlayRef) return

    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      backdropClass: 'blurred-bg',
    })

    const componentPortal = new ComponentPortal(UpgradePlanDialogComponent)
    const componentRef = this.overlayRef.attach(componentPortal)

    componentRef.instance.closeOverlay.subscribe(() => {
      this.closeOverlay()
      // componentRef.instance._closeOverlay()
    })

    this.overlayRef.backdropClick().subscribe(() => {
      // this.closeOverlay()
      componentRef.instance._closeOverlay()
    })

    this.overlayRef.detachments().subscribe(() => {
      this.overlayRef = null
    })
  }

  protected closeOverlay() {
    this.overlayRef?.dispose()
  }
}
