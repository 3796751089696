import { CommonModule } from '@angular/common'
import { Component, computed, EventEmitter, input, Input, Output } from '@angular/core'
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms'
import { NgxTippyModule } from 'ngx-tippy-wrapper'
import { VideoComponentV0_0_0 } from 'src/app/main/interfaces/template/v0_0_0'
import { Video } from 'src/app/main/interfaces/video.interface'
import { VideoService } from 'src/app/main/services/video.service'
import { IconModule } from 'src/app/ui/icon/icon.module'
import { SliderComponent } from 'src/app/ui/slider/slider.component'

@Component({
  selector: 'app-action-set-video-data',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, SliderComponent, IconModule, NgxTippyModule],
  templateUrl: './action-set-video-data.component.html',
  styleUrl: './action-set-video-data.component.scss',
})
export class ActionSetVideoDataComponent {
  constructor(
    private fb: NonNullableFormBuilder,
    private videoService: VideoService
  ) {}

  public template = input.required<Video>()
  protected templateComponentCount = computed(() => {
    let i = 0

    this.videoService.getVideoTracks(this.template()).forEach(track => {
      this.videoService.getTrackComponents(this.template(), track).forEach(_ => {
        i++
      })
    })

    return i
  })

  public component = input.required<VideoComponentV0_0_0>()
  protected showMute = computed(() => {
    if (this.component().editorData?.isPrimary) {
      return false // we should not show the mute option for primary video
    }

    if (this.component().editorData?.useLibrary) {
      return true // if they are seeing this, the user has explicitly selected a custom clip, so they can tinker with it
    }

    return true
  })

  public subclipMode = input.required<string>()

  protected form = this.fb.group({
    clipCount: [1],
    clipTheme: [''],
    muted: [false],
    scaleType: ['contain-blur'],
  })

  @Output()
  public formChange = this.form.valueChanges
}
