<div class="flex flex-col gap-1 mb-4">
  <label class="text-xs text-mid font-light block">YouTube URL</label>
  <input
    [formControl]="urlControl"
    type="url"
    class="input px-1 py-1 text-xs w-[19.5rem] block text-dark"
    placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ" />
</div>
<label class="text-xs text-mid font-light block mb-1">Preview</label>
<div class="text-center">
  <div id="youtube-player" class="aspect-video w-fit h-[140px] rounded-lg bg-neutral-100"></div>
</div>
