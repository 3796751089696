<ng-container *ngIf="getCanvasSize() as canvas">
  <div
    #container
    class="h-full bg-lighter overflow-hidden rounded border"
    [ngStyle]="{ 'width.px': container.clientHeight * (canvas.width / canvas.height) }">
    <div
      class="origin-top-left relative"
      #canvasEl
      [ngStyle]="{
        'width.px': canvas.width,
        'height.px': canvas.height,
        transform: 'scale(' + container.clientHeight / canvas.height + ')'
      }">
      <div
        #componentEl
        *ngIf="getComponentBounds() as bounds"
        class="absolute bg-brand-transparent"
        [ngStyle]="{
          'width.px': bounds.width,
          'height.px': bounds.height,
          'top.px': bounds.y,
          'left.px': bounds.x
        }"></div>
    </div>
  </div>
</ng-container>
