import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/app/environments/environment'
import { PaginatedResponse } from '../../interfaces/paginated-response'
import { BaseOrganisation, MinimalOrganisation, Organisation } from '../../interfaces/organisation.interface'
import { OrganisationInvite } from '../../interfaces/organisation/organisation-invite'
import { MinimalAccountInformation } from 'src/app/auth/interfaces/account-information'

export interface BasicProcessingLimitResponse {
  maxProcessingSeconds: number
  usedProcessingSeconds: number
}

export interface ProcessingLimitStatisticResponse extends BasicProcessingLimitResponse {
  data: {
    [key: string]: {
      data: ProcessingLimitUserStatisticData[]
      accountInformation: MinimalAccountInformation
    }
  }
}

export interface ProcessingLimitUserStatisticData {
  day: number
  processingSeconds: number
}

export interface BaseAnalyticResponse {
  [key: string]: {
    label: string
    value: number
  }[]
}

@Injectable({
  providedIn: 'root',
})
export class OrganisationApiService {
  constructor(private http: HttpClient) {}

  public listOrganisations(filter?: Partial<Organisation>) {
    return this.http.get<PaginatedResponse<MinimalOrganisation>>(`${environment.baseApiUrl}/organisations/`, {
      params: filter as any,
    })
  }

  public retrieveOrganisation(uuid: string) {
    return this.http.get<Organisation>(`${environment.baseApiUrl}/organisations/${uuid}/`)
  }

  public createOrganisation(organisation: BaseOrganisation) {
    return this.http.post<Organisation>(`${environment.baseApiUrl}/organisations/`, organisation)
  }

  public makeOwner(organisationUuid: string, userUuid: string) {
    return this.http.post<Organisation>(`${environment.baseApiUrl}/organisations/${organisationUuid}/make-owner/`, {
      user: userUuid,
    })
  }

  public removeMember(organisationUuid: string, userUuid: string) {
    return this.http.post<Organisation>(`${environment.baseApiUrl}/organisations/${organisationUuid}/remove-user/`, {
      user: userUuid,
    })
  }

  public deleteOrganisation(organisationUuid: string) {
    return this.http.delete<null>(`${environment.baseApiUrl}/organisations/${organisationUuid}/`)
  }

  public partialUpdateOrganisation(organisationUuid: string, organisation: Partial<BaseOrganisation>) {
    return this.http.patch<Organisation>(`${environment.baseApiUrl}/organisations/${organisationUuid}/`, organisation)
  }

  public inviteMember(organisationUuid: string, email: string) {
    return this.http.post<null>(`${environment.baseApiUrl}/organisation-invites/`, {
      email,
      organisation: organisationUuid,
    })
  }

  public listInvites(organisationUuid: string) {
    return this.http.get<PaginatedResponse<OrganisationInvite>>(
      `${environment.baseApiUrl}/organisation-invites/?organisation=${organisationUuid}`
    )
  }

  public revokeInvite(inviteOrgUuid: string, inviteId: number) {
    return this.http.delete<null>(
      `${environment.baseApiUrl}/organisation-invites/${inviteId}/?organisation=${inviteOrgUuid}`
    )
  }

  public joinOrganisation(organisationCode: string) {
    return this.http.post<Organisation>(`${environment.baseApiUrl}/organisation-invites/accept/`, {
      inviteCode: organisationCode,
    })
  }

  public getProcessingLimits(organisationUuid: string, data: { month: number; year: number }) {
    return this.http.get<BasicProcessingLimitResponse>(
      `${environment.baseApiUrl}/organisations/${organisationUuid}/processing-limits/`,
      {
        params: {
          ...data,
        },
      }
    )
  }

  public getProcessingLimitsStats(organisationUuid: string, data: { month: number; year: number }) {
    return this.http.get<ProcessingLimitStatisticResponse>(
      `${environment.baseApiUrl}/organisations/${organisationUuid}/processing-limit-details/`,
      {
        params: {
          ...data,
        },
      }
    )
  }

  public getVideoCreationStats(organisationUuid: string, data: { month: number; year: number }) {
    return this.http.get<BaseAnalyticResponse>(
      `${environment.baseApiUrl}/organisations/${organisationUuid}/video-creation-details/`,
      {
        params: {
          ...data,
        },
      }
    )
  }
}
