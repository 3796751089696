import { CommonModule } from '@angular/common'
import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { animations } from 'src/app/ui/animations'
import { CardModule } from 'src/app/ui/card/card.module'
import { StepperComponent } from 'src/app/ui/stepper/stepper.component'
import { AiCreationProvideVideoLayoutComponent } from '../../layouts/ai-creation-provide-video-layout/ai-creation-provide-video-layout.component'
import { IconModule } from 'src/app/ui/icon/icon.module'
import {
  AiCreationVideoOptionsLayoutComponent,
  AiOptions,
} from '../../layouts/ai-creation-video-options-layout/ai-creation-video-options-layout.component'
import { VideoService } from '../../services/video.service'
import { OrganisationService } from '../../services/organisation/organisation.service'
import { catchError, combineLatest, filter, from, map, of, Subject, switchMap, take, tap, zip } from 'rxjs'
import { ToastService } from 'src/app/ui/toast/toast.service'
import { VideoCreationService } from '../../services/video-creation/video-creation.service'
import { ThirdPartyVideoInfo, VideoSubtitleType } from '../../services/video-creation/video-creation-api.service'
import { HttpEventType } from '@angular/common/http'
import { toObservable } from '@angular/core/rxjs-interop'

@Component({
  selector: 'app-ai-creation-dialog',
  standalone: true,
  imports: [CardModule, CommonModule, StepperComponent, IconModule, AiCreationVideoOptionsLayoutComponent],
  templateUrl: './ai-creation-dialog.component.html',
  styleUrl: './ai-creation-dialog.component.scss',
  animations: [animations.enterFromBottom, animations.delayedFadeInOutFromRight, animations.fadeInOut],
})
export class AiCreationDialogComponent implements AfterViewInit, OnChanges, OnInit {
  constructor(
    private video: VideoService,
    private organisationService: OrganisationService,
    private toast: ToastService,
    private videoCreationService: VideoCreationService
  ) {}

  protected SHORT_VIDEO_THRESHOLD = 120

  @Output()
  public closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>()

  @Input()
  public set videoUrl(value: string | null) {
    this._videoUrl.set(value)

    if (value) {
      this.isLoadingData.set(true)
      this.videoCreationService.getVideoInfo(value)?.subscribe(res => {
        this.isLoadingData.set(false)
        this.videoData.set(res)
      })
    }
  }

  private _videoUrl = signal<string | null>(null)

  @Input()
  public set videoFile(value: File | null) {
    this._videoFile.set(value)
  }

  private _videoFile = signal<File | null>(null)

  protected videoFileAsUrl = computed(() => {
    if (this._videoFile()) {
      return URL.createObjectURL(this._videoFile()!)
    }

    return ''
  })

  protected selectedCaptions = signal<string>('')

  protected isLoadingData = signal(false)

  protected videoData = signal<ThirdPartyVideoInfo | null>(null)

  protected videoLength = computed(() => {
    if (this._videoFile()) {
      const video = document.createElement('video')
      video.src = URL.createObjectURL(this._videoFile()!)
      const subject = new Subject<number>()
      video.onloadedmetadata = () => {
        subject.next(video.duration)
      }

      video.load()
      return subject.asObservable()
    }

    if (this.videoData()) {
      return of(this.videoData()).pipe(map(data => data?.duration ?? 0))
    }

    return of(0)
  })

  protected videoTitle = computed(() => {
    const videoFile = this._videoFile()
    if (videoFile) {
      return of(videoFile.name)
    }

    if (this.videoData()) {
      return of(this.videoData()?.title ?? '')
    }

    return of('')
  })

  protected videoThumbnail = computed(() => {
    if (this.videoData()) {
      return this.videoData()?.thumbnail
    }

    return ''
  })

  protected videoLengthAsTimeString = computed(() => {
    return this.videoLength().pipe(
      map(length => {
        const minutes = Math.floor(length / 60)
        const seconds = Math.round(length % 60)
        if (minutes === 0) {
          return `${seconds}s`
        }

        return `${minutes}m ${seconds}s`
      })
    )
  })

  protected videoDataObservable = computed(() =>
    combineLatest([this.videoLengthAsTimeString(), this.videoTitle()]).pipe(
      map(([length, title]) => ({ length, title }))
    )
  )

  private videoType = computed(() => {
    const videoUrl = this._videoUrl()
    if (videoUrl) {
      if (videoUrl.includes('youtube.com') || videoUrl.includes('youtu.be')) {
        return 'youtube'
      }

      if (videoUrl.includes('twitch.tv')) {
        return 'twitch'
      }

      return 'uploaded'
    }

    return 'uploaded'
  })

  public showDialog: boolean = true

  @ViewChild('container')
  protected container?: ElementRef<HTMLDivElement>
  protected showNextClickButton = signal(false)
  protected showPreviousClickButton = signal(false)
  protected onNextClick() {
    if (this.container) {
      this.container.nativeElement.scrollTo({
        left: this.container.nativeElement.scrollLeft + 200,
        behavior: 'smooth',
      })
    }
  }

  protected onPreviousClick() {
    if (this.container) {
      this.container.nativeElement.scrollTo({
        left: this.container.nativeElement.scrollLeft - 200,
        behavior: 'smooth',
      })
    }
  }

  protected loadingSubtitles = signal(false)
  protected subtitles = signal<VideoSubtitleType[] | null>(null)

  protected onScroll() {
    if (this.container) {
      const shouldShowNextClickButton =
        this.container.nativeElement.scrollWidth - 12 > this.container.nativeElement.clientWidth &&
        this.container.nativeElement.scrollLeft <=
          this.container.nativeElement.scrollWidth - this.container.nativeElement.clientWidth - 12

      const shouldShowPreviousClickButton =
        this.container.nativeElement.scrollLeft > 0 &&
        this.container.nativeElement.scrollWidth > this.container.nativeElement.clientWidth

      this.showNextClickButton.set(shouldShowNextClickButton)
      this.showPreviousClickButton.set(shouldShowPreviousClickButton)

      // this.cdr.detectChanges()
    }
  }

  // 0: provide video (youtube url, upload video etc)
  // 1: fine tune (max clips, max duration, themes to base clips on)
  // TODO: template selection and settings (subtitles, template to select, etc)
  protected currentStep: number = 0

  protected aiOptions: AiOptions | null = null

  @Input()
  public closeOverlay = () => {
    this.showDialog = false
  }

  protected attemptCloseDialog() {
    if (!this.showDialog) {
      this.closeDialog.emit()
    }
  }

  protected attemptNextStep() {
    if (this.currentStep < 2) {
      this.currentStep++
    } else {
      this.closeDialog.emit()
    }
  }

  protected canProceedToNextStep() {
    if (this.currentStep === 0) {
      return (
        (Boolean(this._videoUrl()) || Boolean(this._videoFile())) && !this.isLoadingData() && this.selectedCaptions()
      )
    }

    return true
  }

  protected themes = signal<string[]>([])

  protected loading = false
  protected createProject() {
    this.loading = true

    // we upload the video, then create the project.
    this.organisationService
      .getActiveOrganisation()
      .pipe(
        take(1),
        switchMap(organisation => {
          if (!organisation) {
            console.error('No active organisation')
            return []
          }

          const videoFile = this._videoFile()
          if (!videoFile) {
            return of({ videoUuid: null, organisationUuid: organisation.uuid })
          }

          return this.uploadVideoAndDoToasts(videoFile, organisation.uuid).pipe(
            map(response => {
              if (!response) throw new Error('Failed to upload video')

              return { videoUuid: response.uuid, organisationUuid: organisation.uuid }
            })
          )
        }),
        catchError(err => {
          console.error(err)
          this.loading = false
          this.toast.error('Failed to upload video')
          return []
        }),
        switchMap(data => {
          const videoUuid = data.videoUuid
          const organisationUuid = data.organisationUuid
          const videoData = this.videoData()

          if (!videoUuid && !this._videoUrl()) {
            throw new Error('No video uuid or video url')
          }

          let videoTitle = videoData?.title
          if (!videoTitle) {
            videoTitle = this._videoFile()?.name ?? ''
          }

          return this.videoCreationService.createProject({
            name: videoTitle,
            organisation: organisationUuid,
            image: null, // the image gets set server-side. all good.
            projectType: this.videoType(),
            videoUrl: this._videoUrl() ?? null,
            uploadedVideo: videoUuid,
          })
        }),
        catchError(err => {
          console.error(err)
          this.loading = false
          this.toast.error('Failed to create project - try again later')
          return []
        }),
        switchMap(project => {
          return this.videoCreationService.generateClipsForProject({
            projectUuid: project.uuid,
            organisationUuid: project.organisation,
            themes: this.themes(),
            selectedCaptions: this.selectedCaptions(),
          })
        }),
        catchError(err => {
          console.error(err)
          this.loading = false
          this.toast.error('Failed to generate clips - try again later')
          return []
        })
      )
      .subscribe(response => {
        this.toast.success('Project created!')
        this.loading = false
        this.closeDialog.emit(true)
      })
  }

  private uploadVideoAndDoToasts(videoFile: File, organisationUuid: string) {
    const toast = this.toast.loading('Video uploading - 0%')

    return this.video.uploadVideo(videoFile, organisationUuid).pipe(
      tap(response => {
        if (response.type === 3) {
          toast.message = 'Video uploaded!'
          toast.type = 'success'
          setTimeout(() => (this.toast.toasts = this.toast.toasts.filter(t => t !== toast)), 2000)
          return
        }
        if (response.type === 1) {
          const progress = Math.round((response.loaded / (response.total ?? 1)) * 100)
          toast.message = `Video uploading - ${progress}%`
        }
      }),
      filter(response => response?.type === HttpEventType.Response),
      take(1),
      map(response => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (response as any).body as { uuid: string }
      })
    )
  }

  ngAfterViewInit(): void {
    if (!this.container) return

    // scroll the container to the middle child.
    const middleChild = Math.floor(this.container.nativeElement.children.length / 2)
    const middleChildElement = this.container.nativeElement.children[middleChild] as HTMLElement
    this.container.nativeElement.scrollLeft = middleChildElement.offsetLeft / 2 - middleChildElement.clientWidth / 2
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['videoFile'] && changes['videoFile'].currentValue) {
    //   this.updateVideoLengthFromFile(changes['videoFile'].currentValue)
    // }
  }

  ngOnInit(): void {
    this.videoCreationService.getSubtitleTypes().subscribe(subtitles => {
      this.subtitles.set(subtitles)
      // this.selectedCaptions.set(subtitles[0].name)
    })
    // if (this.videoFile) {
    //   this.updateVideoLengthFromFile(this.videoFile)
    // }
  }

  // private updateVideoLengthFromFile(file: File) {
  //   const video = document.createElement('video')
  //   video.src = URL.createObjectURL(file)
  //   video.onloadedmetadata = () => {
  //     this.videoLength.set(video.duration)
  //   }

  //   video.load()
  // }
}
