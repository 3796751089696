import { CommonModule } from '@angular/common'
import { Component, OnInit, signal } from '@angular/core'
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { catchError } from 'rxjs'
import { AuthService } from 'src/app/auth/services/auth/auth.service'
import { ToastService } from 'src/app/ui/toast/toast.service'

@Component({
  selector: 'app-account-settings-general-layout',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './account-settings-general-layout.component.html',
  styleUrl: './account-settings-general-layout.component.scss',
})
export class AccountSettingsGeneralLayoutComponent implements OnInit {
  constructor(
    private fb: NonNullableFormBuilder,
    private authService: AuthService,
    private toast: ToastService
  ) {}

  protected generalForm = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: [''],
    email: ['', [Validators.required, Validators.email]],
  })
  protected isSavingGeneralForm = signal(false)

  ngOnInit(): void {
    this.authService.retrievePersistentUser().subscribe(user => {
      this.generalForm.controls.email.disable()

      if (!user) {
        this.generalForm.reset()
        return
      }

      this.generalForm.patchValue({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      })
    })
  }

  protected isGeneralFormValid(): boolean {
    if (!this.generalForm.valid) return false

    const user = this.authService.cachedAccountInformation
    if (!user) return false

    const { firstName, lastName } = this.generalForm.value
    return user.firstName !== firstName || user.lastName !== lastName
  }

  protected saveGeneralForm(): void {
    this.isSavingGeneralForm.set(true)

    const { firstName, lastName } = this.generalForm.value // we exclude the email to be safe

    this.authService
      .updateAccountInformation({ firstName, lastName })
      .pipe(
        catchError(() => {
          this.isSavingGeneralForm.set(false)
          this.toast.error('Failed to save your account information. Please try again.')
          return []
        })
      )
      .subscribe(() => {
        this.isSavingGeneralForm.set(false)
        this.toast.success('Successfully saved account information!')
      })
  }
}
