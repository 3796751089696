<form class="mt-4 flex flex-col gap-2" [formGroup]="form">
  @if (canChangeVideoType()) {
  <div class="p-1 mb-1">
    <label class="text-xs text-dark font-light block mb-1">Select video source</label>
    <select formControlName="videoType" class="input px-1 py-1 text-xs w-[19.5rem] block text-dark">
      <option value="file">Upload video</option>
      <option value="youtube">YouTube URL</option>
    </select>
  </div>
  <div class="w-full h-px bg-neutral-100 mb-1"></div>
  } @if (form.controls.videoType.value === 'file') {
  <app-action-video-source-file
    (videoDurationChanged)="onVideoDurationChange($event)"
    [subclipData]="subclipData"
    (videoFileUploaded)="onFileUploaded($event)" />
  } @if (form.controls.videoType.value === 'youtube') {
  <app-action-video-source-youtube
    (videoDurationChanged)="onVideoDurationChange($event)"
    [subclipData]="subclipData"
    (urlChanged)="onUrlChange($event)" />
  } @if (form.controls.subclipMode.value === 'manual' && selectedVideoDuration) {
  <div [@animateHeight] class="overflow-hidden transition-all">
    <div class="w-full pr-16 px-1">
      <ui-double-slider
        #slider
        class="w-full"
        [min]="0"
        [max]="selectedVideoDuration"
        [step]="1"
        [value]="[form.get('subclipStart')?.value ?? 0, form.get('subclipEnd')?.value ?? 0]"
        [maxRange]="300"
        (valueChange)="onSliderChange($event)" />
    </div>
    <div>
      <div class="text-xs text-neutral-500 font-light text-left mb-1">Manually adjust subclip</div>
      <div class="flex gap-2 items-center text-xs">
        <div class="text-right">
          <div class="input px-1 py-1 text-xs block text-right text-dark flex gap-0.5">
            <input
              formControlName="subclipStart"
              type="number"
              class="w-12 focus:outline-none active:outline-none text-right"
              placeholder="Start" />
            s
          </div>
        </div>
        -
        <div class="text-right">
          <div class="input px-1 py-1 text-xs block text-right text-dark flex gap-0.5">
            <input
              formControlName="subclipEnd"
              type="number"
              class="w-12 focus:outline-none active:outline-none text-right"
              placeholder="End" />
            s
          </div>
        </div>
      </div>
    </div>
  </div>
  } @if (selectedVideoDuration) {
  <div [@animateHeight] class="mt-4">
    <p class="text-xs text-mid font-light block">How would you like to select the clip?</p>
    <div class="grid grid-cols-2 gap-2 mt-2">
      <button
        class="border rounded-lg h-20 flex items-center p-2 gap-2"
        [ngClass]="{ 'cursor-not-allowed bg-neutral-100': validityState !== 'valid' }"
        (click)="validityState === 'valid' ? auto.click() : ''">
        <input
          #auto
          type="radio"
          class="input"
          name="subclipMode"
          [disabled]="validityState !== 'valid'"
          (change)="validityState === 'valid' ? form.controls.subclipMode.setValue('auto') : ''" />
        <div class="flex flex-col items-start text-left">
          <h1
            class="font-bold text-sm"
            [ngClass]="{ 'text-dark': validityState === 'valid', 'text-neutral-400': validityState !== 'valid' }">
            Use AI
          </h1>
          <p
            class="text-xs"
            [ngClass]="{
              'text-neutral-500': validityState === 'valid',
              'text-neutral-400': validityState !== 'valid'
            }">
            Automatically select the best clip from the video.
          </p>
        </div>
      </button>
      <button class="border rounded-lg h-20 flex items-center p-2 gap-2" (click)="manual.click()">
        <input
          #manual
          type="radio"
          class="input"
          name="subclipMode"
          (change)="form.controls.subclipMode.setValue('manual')" />
        <div class="flex flex-col items-start text-left">
          <h1 class="font-bold text-dark text-sm">Manual</h1>
          <p class="text-xs text-neutral-500">Manually select the clip from the video.</p>
        </div>
      </button>
    </div>
  </div>
  }
</form>
