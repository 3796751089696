<div>
  <div class="flex gap-2 mb-1 font-semibold text-darkest">
    <img src="./assets/img/youtube.png" class="w-6 aspect-square rounded" />
    YouTube Video
  </div>
  <div class="text-sm ml-8 text-darker font-light">Add a YouTube video to your video.</div>
</div>
<div class="mt-12">
  <form [formGroup]="form" class="flex flex-col items-end gap-2">
    <div class="text-right">
      <label class="text-xs text-mid font-light">YouTube URL</label>
      <input formControlName="url" type="url" class="input px-1 py-1 text-sm w-72 block text-right text-dark" />
    </div>
    <label class="text-xs text-mid font-light text-right block">Subclip</label>
    <div class="px-1 w-full pl-16">
      <div #youtubePlayer class="aspect-video h-[200px] w-full rounded-lg bg-neutral-100"></div>
    </div>
    <div class="w-full pl-16 px-1">
      <ui-double-slider
        *ngIf="selectedVideoDuration"
        #slider
        class="w-full"
        [min]="0"
        [max]="selectedVideoDuration"
        [step]="1"
        [value]="[form.get('subclipStart')?.value ?? 0, form.get('subclipEnd')?.value ?? 0]"
        [maxRange]="300"
        (valueChange)="onSliderChange($event)" />
    </div>
    <div>
      <div class="text-xs text-neutral-500 font-light text-right mb-1">Manually adjust subclip</div>
      <div class="flex justify-end gap-2 items-center text-xs">
        <div class="text-right">
          <div class="input px-1 py-1 text-xs block text-right text-dark flex gap-0.5">
            <input
              formControlName="subclipStart"
              type="number"
              class="w-12 focus:outline-none active:outline-none text-right"
              placeholder="Start" />
            s
          </div>
        </div>
        -
        <div class="text-right">
          <div class="input px-1 py-1 text-xs block text-right text-dark flex gap-0.5">
            <input
              formControlName="subclipEnd"
              type="number"
              class="w-12 focus:outline-none active:outline-none text-right"
              placeholder="End" />
            s
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="text-right">
      <label class="text-xs text-mid font-light">Start Time</label>
      <input
        formControlName="startTime"
        type="number"
        class="input px-1 py-1 text-sm w-72 block text-right text-dark"
        ngxTippy="Start time of the video in seconds - you probably don't need to change this."
        [tippyProps]="{ placement: 'left', trigger: 'focus active' }" />
    </div> -->
    <div class="text-right">
      <label class="text-xs text-mid font-light">Scaling Method</label>
      <!-- <input formControlName="url" type="url" class="input px-1 py-1 text-sm w-72 block text-right text-dark" /> -->
      <select formControlName="scaleType" class="input px-1 py-1 text-sm w-fit block text-right text-dark">
        <option value="contain-blur">Contain and Blur</option>
        <option value="contain">Contain</option>
        <option value="stretch">Stretch</option>
      </select>
    </div>
    <div class="text-right flex flex-col items-end gap-1">
      <label class="text-xs text-mid font-light">Mute Video</label>
      <input formControlName="mute" type="checkbox" class="input px-1 py-1 text-sm block text-right text-dark mr-1" />
    </div>
  </form>
</div>
