import { CommonModule } from '@angular/common'
import { Component, computed, input, OnChanges, OnInit, Signal, signal, SimpleChanges } from '@angular/core'
import { ApexChart, ApexStroke, NgApexchartsModule } from 'ng-apexcharts'
import { switchMap } from 'rxjs'
import { ProcessingLimitStatisticResponse } from 'src/app/main/services/organisation/organisation-api.service'
import { OrganisationService } from 'src/app/main/services/organisation/organisation.service'

type ChartOptions = {
  series: ApexAxisChartSeries
  chart: ApexChart
  xaxis: ApexXAxis
  yaxis: ApexYAxis
  title: ApexTitleSubtitle
  dataLabels: ApexDataLabels
  stroke: ApexStroke
}

@Component({
  selector: 'app-processing-minutes-area-chart',
  standalone: true,
  imports: [NgApexchartsModule, CommonModule],
  templateUrl: './processing-minutes-area-chart.component.html',
  styleUrl: './processing-minutes-area-chart.component.scss',
})
export class ProcessingMinutesAreaChartComponent implements OnInit, OnChanges {
  constructor(private organisationService: OrganisationService) {}

  public month = input.required<number>()
  public year = input.required<number>()
  public height = input<number>(350)

  public data = input<ProcessingLimitStatisticResponse>()

  private monthAsStr = computed(() => {
    const dateStr = new Date(this.year(), this.month() - 1, 1).toLocaleDateString('en-US', {
      month: 'long',
    })

    return dateStr.charAt(0).toUpperCase() + dateStr.slice(1) // titlecase
  })

  private organisationName = signal<string | null>(null) // Should be done with a subscription. Will do this later?

  protected chartOptions: Signal<Partial<ChartOptions>> = computed(() => {
    return {
      chart: {
        type: 'area',
        height: this.height(),
        toolbar: {
          show: false,
          autoSelected: 'pan',
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 500,
        },
      },
      xaxis: {
        type: 'datetime',
        categories: this.xAxisCategories(),
      },
      yaxis: {
        max: this.secondsToMinutes(this.processingMinutesData()?.maxProcessingSeconds || 0),
        min: 0,
      },
      series: this.chartSeriesData(),
      // title: {
      //   text: `Processing Minute usage for ${this.organisationName()} in ${this.monthAsStr()} ${this.year()}`,
      //   align: 'left',
      // },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
        colors: ['#A2D1FD'],
        width: 3,
      },
    }
  })

  protected processingMinutesData = signal<ProcessingLimitStatisticResponse | null>(null)
  protected chartSeriesData = computed(() => {
    const data = this.processingMinutesData()

    if (!data) {
      return []
    }

    // we need to mutate the data to get n + 1 different arrays, where n is the number of users in the org.
    // the arrays should be:
    // 0: the total processing minutes
    // n: the processing minutes for the nth user

    const totalDataObj: number[] = []
    const userDatas: {
      data: number[]
      name: string
    }[] = []
    for (const key of Object.keys(data.data)) {
      const userData = data.data[key]
      // userDatas.push({
      //   data: userData.data.map(data => this.secondsToMinutes(data.processingSeconds)),
      //   name: `${userData.accountInformation.firstName} ${userData.accountInformation.lastName}`,
      // })

      userData.data.forEach((dataPoint, i) => {
        totalDataObj[i] = (totalDataObj[i] || 0) + this.secondsToMinutes(dataPoint.processingSeconds)
      })
    }

    return [
      {
        name: 'Total',
        data: totalDataObj,
        color: '#A2D1FD',
      },
      ...userDatas.map(({ name, data }) => {
        return {
          name,
          data,
        }
      }),
    ]
  })

  protected xAxisCategories = computed(() => {
    const data = this.processingMinutesData()

    if (!data) {
      return []
    }

    // we only need the first. the rest are the same
    return data.data[Object.keys(data.data)[0]].data.map(
      dataPoint => `${this.year()}-${this.month()}-${dataPoint.day}T00:00:00Z`
    )
  })

  protected processingMinutesData$ = this.organisationService.getActiveOrganisation().pipe(
    switchMap(organisation => {
      if (!organisation) {
        return []
      }

      return this.organisationService.getProcessingLimitsStats(organisation.uuid, {
        month: this.month(),
        year: this.year(),
      })
    })
  )

  protected secondsToMinutes(seconds: number): number {
    return Math.ceil(seconds / 60)
  }

  ngOnInit(): void {
    this.organisationService.getActiveOrganisation().subscribe(organisation => {
      this.organisationName.set(organisation?.name || null)
    })

    const data = this.data()

    if (data) {
      this.processingMinutesData.set(data)
      return
    }

    this.processingMinutesData$.subscribe(data => {
      this.processingMinutesData.set(data)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    const dataChange = changes['data']
    if (dataChange) {
      this.processingMinutesData.set(dataChange.currentValue)
    }
  }
}
