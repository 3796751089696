import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Output, signal } from '@angular/core'
import { animations } from 'src/app/ui/animations'
import { CardModule } from 'src/app/ui/card/card.module'
import { IconModule } from 'src/app/ui/icon/icon.module'
import { PaymentService } from '../../services/payment/payment.service'

@Component({
  selector: 'app-upgrade-plan-dialog',
  standalone: true,
  imports: [IconModule, CardModule, CommonModule],
  templateUrl: './upgrade-plan-dialog.component.html',
  styleUrl: './upgrade-plan-dialog.component.scss',
  animations: [animations.enterFromBottom],
})
export class UpgradePlanDialogComponent {
  constructor(private paymentService: PaymentService) {}

  @Output()
  public readonly closeOverlay = new EventEmitter<void>()

  protected readonly showDialog = signal(true)

  public _closeOverlay() {
    this.showDialog.set(false)
  }

  protected attemptCloseDialog() {
    if (this.showDialog()) return

    this.closeOverlay.emit()
  }

  protected loadingStripeCheckout = signal(false)
  protected loadStripeCheckout() {
    this.loadingStripeCheckout.set(true)
    this.paymentService.loadStripeCheckout().subscribe({
      next: ({ checkoutUrl }) => {
        window.location.href = checkoutUrl
      },
      error: () => {
        this.loadingStripeCheckout.set(false)
      },
    })
  }
}
