import { Injectable } from '@angular/core'
import { PaymentApiService } from './payment-api.service'

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private paymentApiService: PaymentApiService) {}

  public loadStripeCheckout() {
    return this.paymentApiService.loadStripeCheckout()
  }
}
