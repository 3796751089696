import { CommonModule } from '@angular/common'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { catchError, tap } from 'rxjs'
import {
  LibraryCategory,
  MinimalLibraryCategory,
} from 'src/app/main/interfaces/clip-library/library-category.interface'
import { LibraryClip } from 'src/app/main/interfaces/clip-library/library-clip.interface'
import { PaginatedResponse } from 'src/app/main/interfaces/paginated-response'
import { ClipLibraryService } from 'src/app/main/services/clip-library/clip-library.service'

@Component({
  selector: 'app-action-set-video-library',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './action-set-video-library.component.html',
  styleUrl: './action-set-video-library.component.scss',
})
export class ActionSetVideoLibraryComponent implements OnInit {
  @Output()
  public clipSelected = new EventEmitter<LibraryClip>()

  constructor(private clipLibraryService: ClipLibraryService) {}

  protected loadingClipCategories = false
  protected errorLoadingClipCategories = false
  protected clipCategories: PaginatedResponse<MinimalLibraryCategory> | null = null
  protected clips: LibraryCategory | null = null

  protected sectionOpen: 'libraries' | 'clips' = 'libraries'

  public LIBRARY_COLOUR_TINTS = ['#bae1ff', '#baffc9', '#ffffba', '#ffdfba', '#ffb3ba']

  ngOnInit(): void {
    this.loadClipCategories()
  }

  private loadClipCategories() {
    this.clipLibraryService
      .listClipCategories()
      .pipe(
        tap(() => {
          this.loadingClipCategories = true
          this.errorLoadingClipCategories = false
        }),
        catchError(() => {
          this.errorLoadingClipCategories = true
          return []
        })
      )
      .subscribe(response => {
        this.clipCategories = response
        this.loadingClipCategories = false
      })
  }

  private loadClips(category: MinimalLibraryCategory) {
    this.clipLibraryService
      .retrieveClipCategory(category.uuid)
      .pipe(
        tap(() => {
          this.loadingClipCategories = true
          this.errorLoadingClipCategories = false
        }),
        catchError(() => {
          this.errorLoadingClipCategories = true
          return []
        })
      )
      .subscribe(response => {
        this.clips = response
        this.loadingClipCategories = false
      })
  }

  protected openClipSection(category: MinimalLibraryCategory) {
    this.sectionOpen = 'clips'
    this.loadClips(category)
  }

  protected onClipSelected(clip: LibraryClip) {
    this.clipSelected.emit(clip)
  }
}
