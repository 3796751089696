import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { Video } from 'src/app/main/interfaces/video.interface'
import { ToastService } from '../toast/toast.service'
import { VideoService } from 'src/app/main/services/video.service'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { CreateVideoModalComponent } from 'src/app/main/components/create-video-modal/create-video-modal.component'
import { MinimalVideo } from 'src/app/main/interfaces/minimal.video'
import { animations } from '../animations'
import { catchError, of, timer } from 'rxjs'
import { RenderErrorModalComponent } from './render-error-modal/render-error-modal.component'
import { Render } from 'src/app/main/interfaces/render'
import { VideoPreviewComponent } from '../video-preview/video-preview.component'
import { FocusTrap, FocusTrapFactory } from '@angular/cdk/a11y'

@Component({
  selector: 'ui-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss'],
  animations: [animations.fadeInOut],
})
export class TemplateCardComponent {
  constructor(
    private toast: ToastService,
    private videoService: VideoService,
    private overlay: Overlay,
    private focusTrapFactory: FocusTrapFactory
  ) {}
  @Input() public template?: MinimalVideo

  private overlayRef: OverlayRef | undefined
  private errorOverlayRef: OverlayRef | undefined

  protected videoIsActive = false

  @ViewChild('videoContainer') protected videoContainer?: ElementRef<HTMLDivElement>
  @ViewChild('videoElement') protected videoElement?: VideoPreviewComponent
  protected videoDuration?: number

  private focusTrap: FocusTrap | null = null

  protected openVideoCreationModal() {
    if (!this.template) return
    if (this.overlayRef) return

    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'blurred-bg',
    })

    const portal = new ComponentPortal(CreateVideoModalComponent)
    const componentRef = this.overlayRef.attach(portal)

    this.focusTrap = this.focusTrapFactory.create(componentRef.location.nativeElement)
    this.focusTrap.focusInitialElementWhenReady()

    componentRef.instance.template = this.template

    this.overlayRef.backdropClick().subscribe(() => {
      // this.closeOverlay()
      componentRef.instance.showOverlay = false
    })

    componentRef.instance.closeOverlay = (renderUuid?: string, progress?: string | number) => {
      this.closeOverlay()
      if (renderUuid && progress !== undefined) {
        this.getVideoProgress(renderUuid, progress)
      }
    }

    this.overlayRef.detachments().subscribe(() => {
      this.overlayRef = undefined
    })

    this.overlayRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        // this.closeOverlay()
        componentRef.instance.showOverlay = false
      }
    })
  }

  protected getVideoProgress(uuid: string, progress?: string | number) {
    const toast = this.toast.loading(`Creating video... (${progress}%)`)
    this.videoService
      .getRenderProgress(uuid)
      .pipe(
        catchError(() => {
          this.toast.toasts = this.toast.toasts.filter(t => t !== toast)
          this.openErrorModal(null)
          return of(null)
        })
      )
      .subscribe(render => {
        if (!render) return

        switch (render.status) {
          case 'waiting':
            toast.message = `Waiting for resources...`
            break
          case 'fetching-data':
            toast.message = `Fetching data...`
            break
          case 'rendering':
            toast.message = `Rendering...`
            break
          case 'done':
            toast.message = `Video created!`
            toast.type = 'success'
            setTimeout(() => {
              this.toast.toasts = this.toast.toasts.filter(t => t !== toast)
            }, 2000)
            break
          case 'failed':
            this.toast.toasts = this.toast.toasts.filter(t => t !== toast)

            this.openErrorModal(render)

            break
        }
      })
  }

  private closeErrorOverlay() {
    if (this.errorOverlayRef) {
      this.errorOverlayRef.dispose()
      this.errorOverlayRef = undefined
      this.videoService.renderErrorModalOpen = false
    }
  }

  private openErrorModal(render: Render | null) {
    if (this.errorOverlayRef) return

    if (this.videoService.renderErrorModalOpen) return

    this.videoService.renderErrorModalOpen = true

    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()

    this.errorOverlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'blurred-bg',
    })

    const portal = new ComponentPortal(RenderErrorModalComponent)
    const componentRef = this.errorOverlayRef.attach(portal)

    componentRef.instance.render = render ?? undefined

    this.errorOverlayRef.backdropClick().subscribe(() => {
      this.closeErrorOverlay()
    })

    componentRef.instance.closeOverlay = () => {
      this.closeErrorOverlay()
    }

    this.errorOverlayRef.detachments().subscribe(() => {
      this.closeErrorOverlay()
    })

    this.errorOverlayRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.closeErrorOverlay()
      }
    })
  }

  private closeOverlay() {
    if (this.videoElement) {
      this.videoElement.resetVideo()
      this.videoElement.attemptStopVideo(true)
    }

    if (this.overlayRef) {
      this.overlayRef.detach()
      // this.overlayRef = undefined
    }
  }
}
