<div class="px-8 mt-4">
  @if (sectionOpen === 'libraries') { @if (errorLoadingClipCategories) {
  <div>err</div>
  } @else if (loadingClipCategories) {
  <div>loading</div>
  } @else if (clipCategories) { @for (category of clipCategories.results; track category.uuid) {
  <button class="w-full h-36 overflow-hidden rounded relative" (click)="openClipSection(category)">
    <video
      class="w-full h-full object-cover saturate-0 blur-[2px]"
      [src]="category.previewVideo?.video"
      [muted]="'muted'"
      [playsInline]="'playsinline'"
      autoplay></video>
    <div
      class="absolute inset-0 flex items-center justify-center"
      [ngStyle]="{ 'background-color': LIBRARY_COLOUR_TINTS[$index] + '90' }">
      <h1 class="font-medium text-white bg-neutral-800 px-4 py-1 rounded-full">
        {{ category.name }}
      </h1>
    </div>
  </button>
  } } } @else if (sectionOpen === 'clips') { @if (errorLoadingClipCategories) {
  <div>err</div>
  } @else if (loadingClipCategories) {
  <div>loading</div>
  } @else if (clips) { @for (clip of clips.clips; track clip.uuid) {
  <button class="w-full h-36 overflow-hidden rounded relative" (click)="onClipSelected(clip)">
    <video
      class="w-full h-full object-cover"
      [src]="clip.video"
      [muted]="'muted'"
      [playsInline]="'playsinline'"
      autoplay></video>
    <div class="absolute inset-0 flex items-center justify-center">
      <h1 class="font-medium text-white bg-neutral-800 px-4 py-1 rounded-full">
        {{ clip.name }}
      </h1>
    </div>
  </button>
  } } }
</div>
