import { CommonModule } from '@angular/common'
import { Component, OnInit, signal } from '@angular/core'
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { ActivatedRouteSnapshot, RouteReuseStrategy } from '@angular/router'
import { catchError } from 'rxjs'
import { AuthService } from 'src/app/auth/services/auth/auth.service'
import { ToastService } from 'src/app/ui/toast/toast.service'
import { AccountSettingsGeneralLayoutComponent } from '../../layouts/account-settings/account-settings-general-layout/account-settings-general-layout.component'

@Component({
  selector: 'app-account-settings-page',
  standalone: true,
  imports: [AccountSettingsGeneralLayoutComponent],
  templateUrl: './account-settings-page.component.html',
  styleUrl: './account-settings-page.component.scss',
})
export class AccountSettingsPageComponent {}
