import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StatisticsDashboardPageComponent } from './pages/statistics-dashboard-page/statistics-dashboard-page.component'
import { StatisticsDashboardLayoutComponent } from './layouts/statistics-dashboard-layout/statistics-dashboard-layout.component'
import { StatisticsProcessingMinutesComponent } from './components/statistics-processing-minutes/statistics-processing-minutes.component'
import { UiModule } from '../ui/ui.module'
import { ProcessingMinutesAreaChartComponent } from './components/charts/processing-minutes-area-chart/processing-minutes-area-chart.component'
import { ProcessingMinutesUsageChartComponent } from './components/charts/processing-minutes-usage-chart/processing-minutes-usage-chart.component'
import { VideoCreationChartComponent } from './components/charts/video-creation-chart/video-creation-chart.component'

@NgModule({
  declarations: [
    StatisticsDashboardPageComponent,
    StatisticsDashboardLayoutComponent,
    StatisticsProcessingMinutesComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    ProcessingMinutesAreaChartComponent,
    ProcessingMinutesUsageChartComponent,
    VideoCreationChartComponent,
  ],
})
export class StatisticsModule {}
