import {
  Directive,
  EventEmitter,
  input,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core'
import { VideoComponentV0_0_0 } from '../interfaces/template/v0_0_0'
import { CreateVideoActionAddVideoComponent } from '../components/create-video-actions/create-video-action-add-video/create-video-action-add-video.component'
import { Video } from '../interfaces/video.interface'
import { CreateVideoActionAddSubtitlesComponent } from '../components/create-video-actions/create-video-action-add-subtitles/create-video-action-add-subtitles.component'

export interface TemplateAction {
  action: 'addVideo' | 'addYouTube' | 'addSubtitles'
  component?: VideoComponentV0_0_0
}

@Directive({
  selector: '[appCreateVideoStep]',
  standalone: true,
})
export class CreateVideoStepDirective implements OnInit, OnChanges {
  constructor(private viewContainerRef: ViewContainerRef) {}

  @Input({ required: true })
  public action!: TemplateAction

  @Output()
  public componentUpdated = new EventEmitter<VideoComponentV0_0_0 | null>()

  @Output()
  public fileAdded = new EventEmitter<{ file: File; componentId: string }>()

  public template = input.required<Video>()

  public data: { [key: string]: unknown } = {}

  ngOnInit(): void {
    this.setActionElement()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['action']) {
      this.setActionElement()
    }
  }

  private setActionElement(): void {
    this.viewContainerRef.clear()

    if (this.action.action === 'addVideo' || this.action.action === 'addYouTube') {
      if (!this.action.component) return

      const actionFileType = this.action.action === 'addYouTube' ? 'youtube' : 'file'
      const isGenericVideo = this.action.action === 'addVideo'

      const comp = this.viewContainerRef.createComponent(CreateVideoActionAddVideoComponent)
      comp.instance.component = this.action.component
      comp.instance.template = this.template()
      comp.instance.setVideoType(actionFileType, !isGenericVideo)
      comp.instance.componentCreated.subscribe(comp => this.componentUpdated.emit(comp))
      comp.instance.fileAdded.subscribe(data => this.fileAdded.emit(data))
    } else if (this.action.action === 'addSubtitles') {
      const comp = this.viewContainerRef.createComponent(CreateVideoActionAddSubtitlesComponent)
      comp.instance.component = this.action.component
      comp.instance.template = this.template()
      comp.instance.componentCreated.subscribe(comp => this.componentUpdated.emit(comp))
    }
  }
}
