import { Injectable } from '@angular/core'
import { ClipLibraryApiService } from './clip-library-api.service'

@Injectable({
  providedIn: 'root',
})
export class ClipLibraryService {
  constructor(private api: ClipLibraryApiService) {}

  public listClipCategories() {
    return this.api.listClipCategories()
  }

  public retrieveClipCategory(categoryUuid: string) {
    return this.api.retrieveClipCategory(categoryUuid)
  }
}
