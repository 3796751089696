import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/app/environments/environment'
import { PaginatedResponse } from '../interfaces/paginated-response'
import { Video } from '../interfaces/video.interface'
import { Render } from '../interfaces/render'
import { MinimalVideo } from '../interfaces/minimal.video'
import { PaginatedRenderFilter } from './video.service'
import { AiOptions } from '../layouts/ai-creation-video-options-layout/ai-creation-video-options-layout.component'
import { MinimalAccountInformation } from 'src/app/auth/interfaces/account-information'
import { Project } from '../interfaces/project'
import { map, of } from 'rxjs'

export interface StoryboardData {
  columns: number
  rows: number
  height: number
  width: number
  image: string
}

export interface VideoUploadResponse {
  created: string
  organisation: string
  updated: string
  user: MinimalAccountInformation
  uuid: string
  video: string
}

@Injectable({
  providedIn: 'root',
})
export class VideoApiService {
  constructor(public http: HttpClient) {}

  public listTemplates(organisationUuid: string | undefined | null) {
    return this.http.get<PaginatedResponse<MinimalVideo>>(`${environment.baseApiUrl}/video/templates/`, {
      params: {
        organisation: organisationUuid || '',
      },
    })
  }

  public createVideo(template: Video | MinimalVideo) {
    return this.http.post<Video>(`${environment.baseApiUrl}/video/`, {
      ...template,
      is_template: false,
      based_on: template.uuid,
    })
  }

  public renderVideo(video: Video, outputResolution: { width: number; height: number }, organisationUuid: string) {
    return this.http.post<{ render: string; progress: string | number }>(
      `${environment.baseApiUrl}/video/${video.uuid}/render/?organisation=${organisationUuid}`,
      {
        output_x: outputResolution.width,
        output_y: outputResolution.height,
      }
    )
  }

  public getRenderProgress(renderUuid: string, activeOrganisationUuid: string) {
    return this.http.get<Render>(`${environment.baseApiUrl}/render/${renderUuid}/`, {
      params: {
        organisation: activeOrganisationUuid,
      },
    })
  }

  public listPreviousRenders(organisationUuid: string, filter: PaginatedRenderFilter = {}) {
    return this.http.get<PaginatedResponse<Render>>(`${environment.baseApiUrl}/render/`, {
      params: {
        organisation: organisationUuid,
        ...filter,
      },
    })
  }

  public getVideo(videoUuid: string, organisationUuid: string) {
    return this.http.get<Video>(`${environment.baseApiUrl}/video/${videoUuid}/`, {
      params: {
        organisation: organisationUuid,
      },
    })
  }

  public getVideoStoryboard(videoUrl: string) {
    return this.http.get<StoryboardData>(`${environment.baseApiUrl}/storyboard/`, {
      params: {
        video_id: videoUrl,
      },
    })
  }

  public createAiVideo(organisationUuid: string, videoUrl: string, options: AiOptions) {
    return this.http.post<unknown>(`${environment.baseApiUrl}/ai/?organisation=${organisationUuid}`, {
      video_url: videoUrl,
      clip_count: options.clipCount,
      subtitles_enabled: options.subtitles,
      clip_theme: options.theme,
    })
  }

  public getVideoUrlSuitableForAi(videoUrl: string) {
    return this.http.get<{ success: string }>(`${environment.baseApiUrl}/ai/`, {
      params: {
        video_url: videoUrl,
      },
    })
  }

  public uploadVideo(file: File, organisationUuid: string) {
    const formData = new FormData()
    formData.append('video', file)
    formData.append('organisation', organisationUuid)

    return this.http.post(`${environment.baseApiUrl}/uploaded-video/?organisation=${organisationUuid}`, formData, {
      reportProgress: true,
      observe: 'events',
    })
  }

  public listProjects(filter: { organisation: string; perPage: number; page: number }) {
    return this.http.get<PaginatedResponse<Project>>(`${environment.baseApiUrl}/projects/`, {
      params: {
        organisation: filter.organisation,
        page_size: filter.perPage.toString(),
        page: filter.page.toString(),
      },
    })
  }

  public getRenderDownloadUrl(renderUuid: string) {
    if (!environment.usePresignedUrlsForDownload) {
      return of(`${environment.baseApiUrl}/render/${renderUuid}/download/`)
    }

    // TODO: test this
    return this.http.get(`${environment.baseApiUrl}/render/${renderUuid}/download/`).pipe(
      map((response: any) => {
        return response['downloadUrl']
      })
    )
  }
}
