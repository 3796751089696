import { Component, Input, Output } from '@angular/core'
import { Render } from 'src/app/main/interfaces/render'
import { animations } from '../../animations'

@Component({
  selector: 'app-render-error-modal',
  templateUrl: './render-error-modal.component.html',
  styleUrls: ['./render-error-modal.component.scss'],
  animations: [animations.enterFromBottom],
})
export class RenderErrorModalComponent {
  @Input()
  public render?: Render

  @Output()
  public closeOverlay: () => void = () => {}
}
