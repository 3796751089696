import { AnimationEvent, animate, animateChild, query, state, style, transition, trigger } from '@angular/animations'
import { Component, computed, EventEmitter, Input, OnInit, Output, signal } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router, RouterModule, Scroll } from '@angular/router'
import { AuthService } from 'src/app/auth/services/auth/auth.service'
import { animations } from 'src/app/ui/animations'
import { UpgradePanelComponent } from './upgrade-panel/upgrade-panel.component'
import { AdminPanelComponent } from './admin-panel/admin-panel.component'
import { AutomationPanelComponent } from './automation-panel/automation-panel.component'
import { DashboardPanelComponent } from './dashboard-panel/dashboard-panel.component'
import { SettingsPanelComponent } from './settings-panel/settings-panel.component'
import { VideoPanelComponent } from './video-panel/video-panel.component'
import { StatisticsPanelComponent } from './statistics-panel/statistics-panel.component'
import { IconModule } from 'src/app/ui/icon/icon.module'
import { CommonModule } from '@angular/common'
import { OrganisationsSelectorComponent } from '../navbar/organisations-selector/organisations-selector.component'

export type SidebarPanel = 'dashboard' | 'videos' | 'automation' | 'statistics' | 'settings' | 'admin'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('slideIn', [
      state('void', style({ transform: 'translateX(-100%)' })),
      state('*', style({ transform: 'translateX(0)' })),
      transition(':enter', [animate('0.2s ease-out'), query('@*', animateChild(), { optional: true })]),
      transition(':leave', [animate('0.05s linear'), query('@*', animateChild(), { optional: true })]),
      transition('* => disabled', [query('@*', animateChild(), { optional: true })]),
    ]),
    animations.animateHeight,
  ],
  standalone: true,
  imports: [
    UpgradePanelComponent,
    AutomationPanelComponent,
    DashboardPanelComponent,
    SettingsPanelComponent,
    VideoPanelComponent,
    StatisticsPanelComponent,
    IconModule,
    CommonModule,
    OrganisationsSelectorComponent,
    RouterModule,
  ],
})
export class SidebarComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  // protected activePanel: SidebarPanel | null = null
  protected readonly activePanel = signal<SidebarPanel | null>(null)
  protected readonly activeSubPanel = signal<string | null>(null)
  protected readonly currentMode = signal<'admin' | 'user'>('user')

  protected readonly shouldShowUpgradePlanButton = signal(false)

  @Output()
  public navigate = new EventEmitter<void>()

  @Input()
  public animationEnabled = false
  public sidebarShouldBeOpen = true

  @Input()
  public showCloseButton = false

  @Output()
  public sidebarClosed = new EventEmitter<void>()

  public closeSidebar() {
    this.sidebarShouldBeOpen = false
  }

  protected onAnimateDone(event: AnimationEvent) {
    if (event.toState === 'void') {
      this.sidebarClosed.emit()
    }
  }

  ngOnInit(): void {
    this.authService.retrievePersistentUser().subscribe(user => {
      if (user) {
        this.shouldShowUpgradePlanButton.set(Boolean(user && !user.isPremium))
      }
    })
    this.activePanel.set(this.getActivePanelFromUrl(this.router.url))
    this.activeSubPanel.set(this.getActiveSubPanelFromUrl(this.router.url))
    this.currentMode.set(this.router.url.startsWith('/admin') ? 'admin' : 'user')
    this.router.events.subscribe(url => {
      if (!(url instanceof NavigationEnd)) {
        return
      }

      this.activePanel.set(this.getActivePanelFromUrl(url.urlAfterRedirects || url.url))
      this.activeSubPanel.set(this.getActiveSubPanelFromUrl(url.urlAfterRedirects || url.url))

      if (url.url.startsWith('/admin')) {
        this.currentMode.set('admin')
      } else {
        this.currentMode.set('user')
      }
    })
  }

  private getActivePanelFromUrl(url: string): 'dashboard' | 'videos' | 'automation' | 'statistics' | 'settings' | null {
    const urlParts = url.split('/')

    if (urlParts.length < 2 || urlParts[1] === '') {
      return 'dashboard'
    }

    if (urlParts[1].startsWith('video')) {
      return 'videos'
    } else if (urlParts[1].startsWith('automation')) {
      return 'automation'
    } else if (urlParts[1].startsWith('statistics')) {
      return 'statistics'
    } else if (urlParts[1].startsWith('settings')) {
      return 'settings'
    }
    return null
  }

  protected isAdmin(): boolean {
    return this.authService.cachedAccountInformation?.isStaff || false
  }

  private getActiveSubPanelFromUrl(url: string): string | null {
    const urlParts = url.split(`/`)
    if (urlParts.length < 3) {
      if (urlParts[1].startsWith('video')) {
        return 'clips'
      }

      return null
    }

    if (urlParts[2].startsWith('projects')) {
      return 'projects'
    } else if (urlParts[2].startsWith('templates')) {
      return 'templates'
    } else if (urlParts[1].startsWith('video')) {
      return 'clips'
    }

    return null
  }
}
