import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms'
import { Observable, of, take, timer } from 'rxjs'
import { VideoComponent } from 'src/app/main/interfaces/component'
import { VideoComponentV0_0_0 } from 'src/app/main/interfaces/template/v0_0_0'
import { ToastService } from 'src/app/ui/toast/toast.service'
import YoutubePlayer from 'youtube-player'
import { YouTubePlayer } from 'youtube-player/dist/types'

@Component({
  selector: 'main-youtube-detail',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss'],
})
export class YouTubeDetailComponent implements OnInit {
  constructor(
    private fb: NonNullableFormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  @Input()
  public component?: VideoComponentV0_0_0

  @ViewChild('youtubePlayer')
  protected youtubePlayer?: ElementRef<HTMLDivElement>

  private player?: YouTubePlayer
  protected selectedVideoDuration = 0
  private MAX_VIDEO_LENGTH = 300

  public form = this.fb.group({
    url: ['', [Validators.required]],
    startTime: [0, [Validators.required]],
    subclipStart: [0, [Validators.required, Validators.min(0)]],
    subclipEnd: [0, [Validators.required, Validators.max(this.MAX_VIDEO_LENGTH)]],
    scaleType: ['contain', [Validators.required]],
    mute: [false, [Validators.required]],
  })

  ngOnInit(): void {
    if (!this.component) {
      throw new Error('Component is not defined')
    }

    const { url, startTime, subclipStart, subclipEnd, scaleType, mute } = this.component.data
    this.form.patchValue({
      url: url as string,
      startTime: startTime as number,
      subclipStart: subclipStart as number,
      subclipEnd: subclipEnd as number,
      scaleType: scaleType as string,
      mute: mute as boolean,
    })

    this.form.controls.url.valueChanges.subscribe(() => this.updateEmbed())
    this.form.controls.subclipStart.valueChanges.subscribe(() => this.updateEmbed())
    this.form.controls.subclipEnd.valueChanges.subscribe(() => this.updateEmbed())
    this.form.controls.mute.valueChanges.subscribe(() => this.muteOrUnmuteEmbed())
  }

  public mutateComponent(): VideoComponentV0_0_0 | null {
    if (!this.component) {
      throw new Error('Component is not defined')
    }

    const { url, startTime, subclipStart, subclipEnd, scaleType, mute } = this.form.getRawValue()
    if (!this.form.valid) {
      return null
    }

    this.component.duration = subclipEnd - subclipStart
    this.component.startTime = startTime

    this.component.data = {
      url,
      startTime,
      subclipStart,
      subclipEnd,
      scaleType,
      mute,
    }

    return this.component
  }

  private getIdFromYouTubeUrl(url: string): string | null {
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/)
    if (!match) {
      return null
    }

    return match[1]
  }

  protected onSliderChange(event: [number, number]) {
    this.form.patchValue({
      subclipStart: event[0],
      subclipEnd: event[1],
    })
  }

  private previousStartTime = 0
  private previousEndTime = 0

  private updateEmbed() {
    if (!this.youtubePlayer) return

    const { url, subclipStart, subclipEnd } = this.form.getRawValue()

    const id = this.getIdFromYouTubeUrl(url ?? '')

    if (!id || subclipStart === undefined || subclipEnd === undefined || subclipStart >= subclipEnd) return

    if (!this.player) {
      this.player = YoutubePlayer(this.youtubePlayer.nativeElement, {
        playerVars: {
          autoplay: 1,
          controls: 0,
          disablekb: 1,
          enablejsapi: 1,
          fs: 0,
          loop: 1,
          modestbranding: 1,
          playsinline: 1,
          rel: 0,
          // origin: window.location.origin,
        },
        height: '200',
      })

      this.player
        .loadVideoById({
          videoId: id,
          startSeconds: subclipStart,
          endSeconds: subclipEnd,
        })
        .then(() => {
          this.player?.seekTo(subclipStart, false)
        })

      this.player.on('stateChange', event => {
        if (event.data === 1) {
          this.player
            ?.playVideo()
            .then(() => this.player?.getDuration())
            .then(duration => {
              if (!duration) return

              const { subclipStart } = this.form.getRawValue()

              this.selectedVideoDuration = Math.floor(duration)
              this.form.controls.subclipEnd.setValidators([
                Validators.max(duration),
                Validators.max(subclipStart + this.MAX_VIDEO_LENGTH),
                Validators.required,
                Validators.min(subclipStart),
              ])

              this.form.controls.subclipEnd.updateValueAndValidity()

              this.cdr.detectChanges()
            })
        }
      })
    } else {
      if (this.previousStartTime !== subclipStart) {
        this.player.seekTo(subclipStart, true)
        this.previousStartTime = subclipStart
      }

      if (this.previousEndTime !== subclipEnd) {
        this.previousEndTime = subclipEnd
        // all this timeout stuff is goofy and doesn't work. we'll just-requeue the video
        this.player?.loadVideoById({
          videoId: id,
          startSeconds: subclipStart,
          endSeconds: subclipEnd,
        })
      }
    }
  }

  private muteOrUnmuteEmbed() {
    if (!this.player) return

    const { mute } = this.form.getRawValue()

    if (mute) {
      this.player.mute()
    } else {
      this.player.unMute()
    }
  }
}
