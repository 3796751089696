<ui-card
  class="p-6 pt-12 w-[30rem] max-w-[100vw] max-full-height overflow-y-auto"
  *ngIf="showOverlay"
  #container
  [@enterFromBottom]
  (@enterFromBottom.done)="attemptCloseDialog()">
  <div class="top-2 right-2 absolute">
    <button
      class="text-xl flex items-center gap-2 p-1 rounded-full hover:bg-neutral-100 transition-colors"
      (click)="showOverlay = false">
      <ng-icon name="heroXMark" class="w-4 h-4" />
    </button>
  </div>
  <ui-stepper [steps]="actions.length" [currentStep]="currentStep" class="mb-8" />
  <div class="relative">
    @if (currentStep === -1) {
    <div [@slide]>
      <div class="flex justify-center items-center mb-6">
        <ui-video-preview
          #videoPreview
          *ngIf="minimalTemplate"
          [video]="minimalTemplate"
          (loadedMetadata)="onVideoLoaded()"
          class="h-72 md:h-96 w-fit block cursor-default"
          [takeInputs]="false"
          [showPointerIfNoPreview]="false"
          [showDuration]="false" />
      </div>
      <div class="px-4">
        <h1 class="text-2xl font-bold mt-4 text-dark flex gap-1 items-center">
          {{ minimalTemplate?.title }}

          <ng-icon name="heroCheckBadgeSolid" class="text-lg" />
        </h1>
        <p class="font-light text-sm">
          {{ minimalTemplate?.description }}
        </p>
      </div>
    </div>
    } @else if (currentStep < actions.length) {
    <ng-container
      *ngIf="fullTemplate"
      appCreateVideoStep
      [action]="actions[currentStep]"
      [template]="fullTemplate"
      (componentUpdated)="onComponentUpdated($event)"
      (fileAdded)="onFileAdded($event)" />
    }
    <button
      *ngIf="currentStep === actions.length || currentStep === -1"
      class="w-full button-primary mt-8 flex items-center justify-center gap-2"
      [disabled]="!fullTemplate"
      (click)="currentStep = currentStep + 1">
      Create video from template
    </button>
  </div>
</ui-card>
