@if (file() === null) {
<div class="flex justify-center mt-2">
  <ui-file-upload [maxFileSize]="MAX_FILE_SIZE" (fileUploaded)="onFileUploaded($event)" />
</div>

} @else if (file()) {
<div class="mt-2">
  <label class="text-xs text-mid font-light block mb-1">Preview</label>
  <video
    [playsInline]="'playsinline'"
    autoplay
    class="w-fit h-[140px] rounded-lg bg-neutral-100 aspect-video"
    (loadeddata)="onLoadedMetadata($event)"
    #video>
    <source [src]="fileUrl()" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>
}
