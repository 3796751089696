import { Component, OnInit, signal } from '@angular/core'
import { VideoService } from '../../services/video.service'
import { OrganisationService } from '../../services/organisation/organisation.service'
import { catchError, interval, map, of, Subject, switchMap, tap } from 'rxjs'
import { Render } from '../../interfaces/render'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { AiCreationDialogComponent } from '../../dialogs/ai-creation-dialog/ai-creation-dialog.component'
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms'
import { CardModule } from 'src/app/ui/card/card.module'
import { IconModule } from 'src/app/ui/icon/icon.module'
import { NgxTippyModule } from 'ngx-tippy-wrapper'
import { CommonModule } from '@angular/common'
import { Project } from '../../interfaces/project'
import { RouterModule } from '@angular/router'
import { ProjectListLayoutComponent } from 'src/app/videos/layouts/project-list-layout/project-list-layout.component'
import { CreateProjectBarComponent } from 'src/app/videos/components/create-project-bar/create-project-bar.component'
import { UiModule } from '../../../ui/ui.module'
import { PaginatedListModule } from '../../../ui/paginated-list/paginated-list.module'

@Component({
  selector: 'main-recent-videos',
  templateUrl: './recent-videos.component.html',
  styleUrls: ['./recent-videos.component.scss'],
  imports: [
    CardModule,
    IconModule,
    CommonModule,
    RouterModule,
    ProjectListLayoutComponent,
    CreateProjectBarComponent,
    UiModule,
  ],
  standalone: true,
})
export class RecentVideosComponent {
  protected refreshSubject = new Subject<void>()
  protected onProjectCreated() {
    this.refreshSubject.next()
  }
}
