<div class="flex items-center gap-4">
  <div class="w-9 h-9 rounded-full items-center justify-center flex bg-[#DFE4EC]">
    <!-- attribution to https://www.svgrepo.com/author/Solar%20Icons/ -->
    <div
      class="w-5 h-5 transition-colors"
      [ngClass]="{
        'text-brand': activePanel() === 'videos',
        'text-[#4D6080]': activePanel() !== 'videos'
      }">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.99981 0.833344C11.5585 0.833344 12.8362 0.833343 13.8956 0.896355V19.1037C12.8362 19.1667 11.5585 19.1667 9.99981 19.1667C8.44102 19.1667 7.16343 19.1667 6.10394 19.1037L6.10395 0.896355C7.16343 0.833343 8.44102 0.833344 9.99981 0.833344ZM10.3769 8.53729C11.3479 9.18986 11.8333 9.51619 11.8333 10C11.8333 10.4838 11.3479 10.8102 10.3769 11.4627C9.3927 12.1242 8.90055 12.4549 8.5336 12.2121C8.16666 11.9693 8.16666 11.3129 8.16666 10C8.16666 8.68716 8.16666 8.03077 8.5336 7.78793C8.90055 7.54508 9.3927 7.87583 10.3769 8.53729Z"
          fill="currentColor" />
        <path
          d="M4.72892 4.72916H1.03625C1.21433 3.60833 1.54849 2.80279 2.17552 2.17576C2.80254 1.54872 3.60808 1.21457 4.72892 1.0365V4.72916Z"
          fill="currentColor" />
        <path
          d="M18.9632 4.72916C18.7852 3.60833 18.451 2.80279 17.824 2.17576C17.197 1.54872 16.3914 1.21457 15.2706 1.0365V4.72916H18.9632Z"
          fill="currentColor" />
        <path
          d="M19.1662 9.31252C19.165 8.06019 19.1569 7.00359 19.1034 6.10419H15.2706V9.31252H19.1662Z"
          fill="currentColor" />
        <path
          d="M17.824 17.8242C17.197 18.4513 16.3914 18.7854 15.2706 18.9635V15.2708H18.9632C18.7852 16.3917 18.451 17.1972 17.824 17.8242Z"
          fill="currentColor" />
        <path
          d="M19.1662 10.6875C19.165 11.9398 19.1569 12.9964 19.1034 13.8958H15.2706V10.6875H19.1662Z"
          fill="currentColor" />
        <path
          d="M4.72892 15.2708V18.9635C3.60808 18.7854 2.80254 18.4513 2.17552 17.8242C1.54848 17.1972 1.21433 16.3917 1.03625 15.2708H4.72892Z"
          fill="currentColor" />
        <path
          d="M4.72893 13.8958H0.896105C0.842617 12.9964 0.834532 11.9398 0.833313 10.6875H4.72893V13.8958Z"
          fill="currentColor" />
        <path
          d="M4.72893 9.31252H0.833313C0.834532 8.06019 0.842617 7.00359 0.896105 6.10419H4.72893V9.31252Z"
          fill="currentColor" />
      </svg>
    </div>
  </div>
  <span
    class="font-semibold"
    [ngClass]="{
      'text-[#0D2A4A]': activePanel() !== 'videos',
      'text-[#042862]': activePanel() === 'videos'
    }">
    Projects & Videos
  </span>
</div>
