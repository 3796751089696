import { Component, input, Input } from '@angular/core'
import { SidebarPanel } from '../sidebar.component'
import { animations } from 'src/app/ui/animations'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'app-video-panel',
  templateUrl: './video-panel.component.html',
  styleUrls: ['./video-panel.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class VideoPanelComponent {
  public readonly activePanel = input<SidebarPanel | null>(null)
}
