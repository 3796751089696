import { Component, EventEmitter, Input, Output } from '@angular/core'
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms'
import { VideoComponentV0_0_0 } from 'src/app/main/interfaces/template/v0_0_0'
import { Video } from 'src/app/main/interfaces/video.interface'
import { VideoService } from 'src/app/main/services/video.service'
import { ComponentLayoutPreviewComponent } from 'src/app/ui/component-layout-preview/component-layout-preview.component'
import { ToggleSliderComponent } from 'src/app/ui/toggle-slider/toggle-slider.component'

@Component({
  selector: 'app-create-video-action-add-subtitles',
  standalone: true,
  imports: [ComponentLayoutPreviewComponent, ToggleSliderComponent, ReactiveFormsModule],
  templateUrl: './create-video-action-add-subtitles.component.html',
  styleUrl: './create-video-action-add-subtitles.component.scss',
})
export class CreateVideoActionAddSubtitlesComponent {
  constructor(
    private fb: NonNullableFormBuilder,
    private videoService: VideoService
  ) {}

  @Input() // not required because not all templates have subtitles by default.
  public component?: VideoComponentV0_0_0

  @Input({ required: true })
  public template!: Video

  @Output()
  public componentCreated = new EventEmitter<VideoComponentV0_0_0 | null>()

  protected form = this.fb.group({
    enabled: [false],
  })

  protected getDummySubtitleComponentForLayout(): VideoComponentV0_0_0 {
    return {
      classname: 'DUMMY',
      width: this.template.data.metadata.width,
      height: 256,
      startTime: 0,
      duration: 0,
      templateVersion: '0',
      id: 'DUMMY',
      data: {},
      x: 0,
      y: 'center',
    }
  }

  protected onSubtitleChange(enabled: boolean) {
    this.form.patchValue({ enabled })
  }

  protected nextStep() {
    const { enabled } = this.form.getRawValue()

    if (!enabled) {
      this.componentCreated.emit(null)
      return
    }

    const comp = this.videoService.createEmptyComponent('video.components.text.TranscriptionComponent', this.template)
    this.componentCreated.emit(comp)
  }
}
