<ui-card class="p-6 px-6 w-full">
  <h1 class="text-dark text-3xl font-bold mb-6 mt-3">Generate video with AI</h1>
  <app-create-project-bar (projectCreated)="onProjectCreated()" />
  <div class="my-8 h-px bg-neutral-100 w-full"></div>
  <div class="flex justify-between items-center gap-8">
    <div>
      <a
        routerLink="/video/projects/"
        class="block w-fit text-neutral-400 tracking-widest uppercase text-xs font-light hover:text-mid transition-colors default-ring">
        View all
        <ng-icon name="heroArrowTopRightOnSquare" class="inline-block" aria-hidden="true"></ng-icon>
      </a>
      <h1 class="text-dark text-2xl font-bold mt-1">Recent projects</h1>
    </div>
    <a
      routerLink="/video/projects"
      class="text-neutral-400 transition-colors items-center justify-center flex w-10 h-10 hover:bg-lighter default-ring rounded-full text-2xl focus:bg-lighter focus:transition-none focus-visible:bg-lighter">
      <ng-icon name="heroChevronRight" class="inline-block" aria-hidden="true"></ng-icon>
    </a>
  </div>
  <app-project-list-layout
    [perPage]="3"
    class="mt-8 block"
    [showPagination]="false"
    [refreshObservable]="refreshSubject.asObservable()" />
</ui-card>
