import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core'
import { Video } from '../../interfaces/video.interface'
import { animations } from 'src/app/ui/animations'
import { ComponentError, VideoService } from '../../services/video.service'
import { ToastService } from 'src/app/ui/toast/toast.service'
import { VideoComponentV0_0_0 } from '../../interfaces/template/v0_0_0'

import { CustomComponentDetailFactoryDirective } from '../../directives/custom-component-detail-factory.directive'
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  concat,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
  timer,
  zip,
} from 'rxjs'
import { MinimalVideo } from '../../interfaces/minimal.video'
import { OrganisationService } from '../../services/organisation/organisation.service'
import { DomPortal, DomPortalOutlet } from '@angular/cdk/portal'
import { VideoPreviewComponent } from 'src/app/ui/video-preview/video-preview.component'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { TemplateAction } from '../../directives/create-video-step.directive'
import { Organisation } from '../../interfaces/organisation.interface'
import { HttpEvent, HttpEventType } from '@angular/common/http'
import { VideoUploadResponse } from '../../services/video-api.service'

interface DataExtendedComponent extends VideoComponentV0_0_0 {
  [key: string]: unknown
}
@Component({
  selector: 'app-create-video-modal',
  templateUrl: './create-video-modal.component.html',
  styleUrls: ['./create-video-modal.component.scss'],
  animations: [
    animations.enterFromBottom,
    animations.fadeInOutFromLeft,
    trigger('widthChange', [
      state('void', style({ width: '0%' })),
      state('*', style({ width: '*' })),
      transition(':enter', [animate('0.2s ease-out')]),
      transition(':leave', [animate('0.2s ease-out')]),
    ]),
  ],
})
export class CreateVideoModalComponent {
  constructor(
    private videoService: VideoService,
    private organisationService: OrganisationService,
    private toast: ToastService,
    private cdr: ChangeDetectorRef
  ) {}

  protected componentUpdated$ = new BehaviorSubject<VideoComponentV0_0_0 | null>(null)

  protected minimalTemplate?: MinimalVideo
  protected fullTemplate?: Video
  protected errorLoadingTemplate?: boolean
  protected creationState: 'waiting' | 'creating' = 'waiting'
  protected selectedComponent: VideoComponentV0_0_0 | null = null

  @Input()
  public set template(template: MinimalVideo) {
    this.minimalTemplate = template
    this.videoService.getVideoByUuid(template.uuid).subscribe(video => {
      this.fullTemplate = video
      this.fullVideoSubject.next(video)

      this.invalidComponents = this.videoService.getInvalidComponents(video)
      this.attemptSetSubtitleState()
      this.actions = this.getTemplateSteps()

      this.videoService.getVideoTracks(video).forEach(track => {
        this.videoService.getTrackComponents(video, track).forEach(component => {
          this.components.push(component)
        })
      })
    })
  }

  public showOverlay = true

  @Output()
  public closeOverlay: (renderUuid?: string, progress?: string | number) => void = () => {}

  protected attemptCloseDialog() {
    if (!this.showOverlay) {
      this.closeOverlay()
    }
  }

  @ViewChild('videoPreview')
  protected videoPreview?: VideoPreviewComponent

  protected actions: TemplateAction[] = []
  public currentStep: number = -1 // we have a -1 step to show the template preview before starting the creation process
  protected components: VideoComponentV0_0_0[] = []

  protected onVideoLoaded() {
    if (!this.videoPreview) return

    this.videoPreview.playVideo(true, true)
  }

  @ViewChildren(CustomComponentDetailFactoryDirective)
  protected componentDetails?: QueryList<CustomComponentDetailFactoryDirective>

  protected loadingFullTemplate = false

  protected error?: string

  protected videoWidth = 0

  protected fullVideoSubject = new BehaviorSubject<Video | null>(null)

  protected subtitlesEnabled = false

  protected toggleSubtitlesEnabled(newState: boolean) {}

  protected fullVideoMethod = (uuid: string) => {
    return this.fullVideoSubject.asObservable()
  }

  protected get componentCount(): number {
    if (!this.fullTemplate) return 0

    let count = 0

    this.videoService.getVideoTracks(this.fullTemplate).forEach(track => {
      count += track.components.length
    })

    return count
  }

  protected canSubmit(): boolean {
    if (!this.componentDetails) return false

    return this.componentDetails.toArray().every(this.checkDirectiveValidity)
  }

  private checkDirectiveValidity(directive: CustomComponentDetailFactoryDirective): boolean {
    if (!directive.component) return false

    return directive.component.form.valid
  }

  protected startCreationProcess() {
    this.creationState = 'creating'
    const firstComponent = this.fullTemplate?.data.tracks[0].components[0]
    if (firstComponent) {
      this.selectedComponent = firstComponent
    }
  }

  protected get video() {
    if (!this.fullTemplate) return this.minimalTemplate

    return this.fullTemplate
  }

  protected get videoPreviewMode() {
    return this.creationState === 'waiting' ? 'preview' : 'component'
  }

  protected onComponentUpdated(component: VideoComponentV0_0_0 | null) {
    if (component) {
      this.fullTemplate = this.videoService.updateComponentById(component.id, component, this.fullTemplate!, true)
    }

    if (this.currentStep === this.actions.length - 1) {
      this.createAndRender()
      return
    }

    this.currentStep += 1
  }

  protected createAndRender() {
    if (!this.canCreateAndRender()) return

    this.setLibraryClipData()

    this.organisationService
      .getActiveOrganisation()
      .pipe(
        take(1),
        switchMap(organisation => {
          if (!organisation) return []

          const observablesToAwait: Observable<unknown>[] = []

          this.filesToUpload.forEach(file => {
            observablesToAwait.push(
              this.videoService.uploadVideo(file.file, organisation.uuid).pipe(
                catchError(error => {
                  console.error(error)
                  this.toast.error(error.error.error || 'Error uploading file')
                  return of(null)
                }),
                filter(response => response?.type === HttpEventType.Response),
                take(1),
                tap(response => {
                  if (!response) return

                  const body: VideoUploadResponse = (response as any).body as VideoUploadResponse
                  const url = body.video

                  const component = this.videoService.getComponentById(this.fullTemplate!, file.componentId)
                  if (!component) return

                  component.data = {
                    ...component.data,
                    url,
                  }

                  this.fullTemplate = this.videoService.updateComponentById(
                    component.id,
                    component,
                    this.fullTemplate!,
                    true
                  )
                })
              )
            )
          })

          if (observablesToAwait.length === 0) return [organisation]

          return concat(...observablesToAwait).pipe(map(() => organisation))
        }),
        switchMap(organisation => {
          if (!this.fullTemplate || !organisation) return []

          this.fullTemplate.organisation = organisation.uuid

          return this.videoService.createVideo(this.fullTemplate)
        }),
        switchMap(video => {
          if (!this.fullTemplate) return []

          return this.videoService.renderVideo(video, { width: 1920, height: 1080 })
        }),
        catchError(error => {
          console.error(error)
          this.toast.error(error.error.error || 'Error creating video')
          this.closeOverlay?.()
          return []
        })
      )
      .subscribe(render => {
        console.log(render)
        this.closeOverlay?.(render.render, render.progress)
      })
  }

  protected canCreateAndRender() {
    return true
    if (!this.fullTemplate) return false

    return this.invalidComponents.length === 0
  }

  protected invalidComponents: ComponentError[] = []

  private attemptSetSubtitleState() {
    if (!this.fullTemplate) return

    const subtitleComponent = this.videoService.getSubtitleComponentFromTemplate(this.fullTemplate.data)
    this.subtitlesEnabled = Boolean(subtitleComponent)
  }

  protected videoUpdated$ = new BehaviorSubject<Video | null>(null)

  protected subtitleToggleChanged(newState: boolean) {
    this.subtitlesEnabled = newState
    if (!this.fullTemplate) return

    if (newState) {
      this.videoService.addComponentToTemplate(this.fullTemplate, 'video.components.text.TranscriptionComponent')
    } else {
      const subtitleComponent = this.videoService.getSubtitleComponentFromTemplate(this.fullTemplate.data)
      if (subtitleComponent) {
        this.videoService.removeComponentFromTemplate(this.fullTemplate, subtitleComponent.id)
      }
    }

    this.invalidComponents = this.videoService.getInvalidComponents(this.fullTemplate)
    this.videoUpdated$.next(this.fullTemplate)
  }

  private getTemplateSteps(): TemplateAction[] {
    if (!this.fullTemplate) return []

    const steps: TemplateAction[] = []

    let subtitlesAdded = false

    this.videoService.getVideoTracks(this.fullTemplate).forEach(track => {
      this.videoService.getTrackComponents(this.fullTemplate!, track).forEach(component => {
        switch (component.classname) {
          case 'video.components.text.TranscriptionComponent':
            if (subtitlesAdded) break
            steps.push({ action: 'addSubtitles', component })
            subtitlesAdded = true
            break
          case 'video.components.video.GenericVideoComponent':
            steps.push({ action: 'addVideo', component })
            break
          case 'video.components.media.YouTubeComponent': // to be phased out
            steps.push({ action: 'addYouTube', component })
            break
          default:
            steps.push({ action: 'addVideo', component })
            break
        }
      })
    })

    if (!subtitlesAdded) steps.push({ action: 'addSubtitles' })

    return steps
  }

  private setLibraryClipData() {
    if (!this.fullTemplate) return

    const duration = this.videoService.getVideoDuration(this.fullTemplate)

    this.videoService.getVideoTracks(this.fullTemplate).forEach(track => {
      this.videoService.getTrackComponents(this.fullTemplate!, track).forEach(component => {
        if (component.editorData?.useLibrary) {
          component.data = {
            ...component.data,
            subclipStart: 2,
            subclipEnd: duration + 2,
          }

          component.duration = duration
        }
      })
    })
  }

  private filesToUpload: { file: File; componentId: string }[] = []

  protected onFileAdded(event: { file: File; componentId: string }) {
    if (!this.fullTemplate) return

    this.filesToUpload.push(event)
  }
}
