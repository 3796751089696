import { Component, Input } from '@angular/core'
import { NonNullableFormBuilder, Validators } from '@angular/forms'
import { VideoComponent } from 'src/app/main/interfaces/component'
import { VideoComponentV0_0_0 } from 'src/app/main/interfaces/template/v0_0_0'

@Component({
  selector: 'main-watermark-detail',
  templateUrl: './watermark.component.html',
  styleUrls: ['./watermark.component.scss'],
})
export class WatermarkDetailComponent {
  constructor(private fb: NonNullableFormBuilder) {}
  @Input() component?: VideoComponentV0_0_0

  public form = this.fb.group({
    text: ['', [Validators.required]],
  })

  public mutateComponent(): VideoComponentV0_0_0 {
    if (!this.component) {
      throw new Error('Component is not defined')
    }

    const { text } = this.form.value

    if (!this.form.valid) {
      throw new Error('Form is not valid')
    }

    if (text === undefined) {
      throw new Error('Form values are undefined')
    }

    this.component.data = {
      text,
    }

    return this.component
  }
}
