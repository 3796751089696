<div class="overflow-hidden w-full">
  @if (videoCreationData(); as data) {
  <apx-chart
    [series]="chartOptions().series!"
    [chart]="chartOptions().chart!"
    [dataLabels]="chartOptions().dataLabels!"
    [xaxis]="chartOptions().xaxis!"
    [yaxis]="chartOptions().yaxis!"
    [stroke]="chartOptions().stroke!" />
  }
</div>
