import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PaginatedResponse } from '../../interfaces/paginated-response'
import { LibraryCategory, MinimalLibraryCategory } from '../../interfaces/clip-library/library-category.interface'
import { environment } from 'src/app/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ClipLibraryApiService {
  constructor(private http: HttpClient) {}

  public listClipCategories() {
    return this.http.get<PaginatedResponse<MinimalLibraryCategory>>(`${environment.baseApiUrl}/library-categories/`)
  }

  public retrieveClipCategory(categoryUuid: string) {
    return this.http.get<LibraryCategory>(`${environment.baseApiUrl}/library-categories/${categoryUuid}/`)
  }
}
